import './style/Product.css';
import React, { useEffect, useState } from 'react';

import specification from '../media/icon_specification.png'
import expand from '../media/icon_expand.png'
import leftPrimary from '../media/icon_left_primary.png'
import leftSecondary from '../media/icon_left_secondary.png'
import rightPrimary from '../media/icon_right_primary.png'
import cart from '../media/cart.png'
import rightSecondary from '../media/icon_right_secondary.png'
import box from '../media/icon_box.png'
import boxGreen from '../media/icon_box_green.png'
import logo from '../media/logo-mtrlz.png'
import sampleProduct from './sample_product.json'
import searchIcon from '../media/search.png'

const Product = () => {
    const [currentProduct, setCurrentProduct] = useState(sampleProduct);
    const [purchaseType, setPurchaseType] = useState(0);
    const [costInCents, setCostInCents] = useState(23274); // 232.74
    const [availableQuantity, setAvailableQuantity] = useState(128); // sampleProduct.quantity
    const [quantity, setQuantity] = useState(1);
    const [offer, setOffer] = useState('200.00');
    const [totalInCents, setTotalInCents] = useState(23274); // 232.74
    const [description, setDescription] = useState(currentProduct.description.substring(0, 300) + "...");
    const [images, setImages] = useState(currentProduct.images);
    const [imageIndex, setImageIndex] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([currentProduct, currentProduct]);

    useEffect(() => {
        if (purchaseType === 0) {
            setTotalInCents(costInCents * quantity);
        } else {
            setTotalInCents(Number(offer) * quantity * 100);
        }
    }, [quantity, offer, purchaseType]);

    const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
        if (e.target.value.length !== 0) {
            setSearchResults([currentProduct, currentProduct]);
        }
    };

    const handleQuantityInputChange = (e) => {
        const value = e.target.value;
        // Use a regex to ensure all input is numeric
        if (/^\d+$/.test(value) && parseInt(value) <= availableQuantity) {
            setQuantity(value);
        } else if (value === '') {
            // Allow empty input to reset the field
            setQuantity('');
        }
    };

    const handleBuyNow = async () => {
            // The data you want to send in the request body
        const requestData = {
            // Add your request data here
            // For example, you may need to send an amount, currency, or other metadata required by your backend
            amount: 1000,
            currency: 'usd',
            // any additional fields
        };
        
        try {
            // Perform the POST request
            const response = await fetch('http://localhost:8080/stripe/create_checkout_session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            // Check if the request was successful
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Parse the response body
            const data = await response.json();

            // Handle the session ID or other response data as needed
            console.log('Checkout session data:', data);

            // If your backend returns a URL for Stripe Checkout, you can redirect the user to it:
            if (data.url) {
                window.location.href = data.url;
            }

        } catch (error) {
            // Handle errors here
            console.error('There was a problem with the checkout session request:', error);
        }
    };

    const handleQuantityBlur = (e) => {
        const value = e.target.value;
        // Ensure input is at least 1 when the input loses focus
        if (value === '' || parseInt(value, 10) < 1) {
            setQuantity(1);
        }
    };

    const handleOfferInputChange = (e) => {
        const value = e.target.value;
        // Regular expression to ensure the input is numeric and can include decimals with at most two digits after the decimal point
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setOffer(value);
        } else if (value === '') {
            // Allow empty input to reset the field
            setOffer('');
        }
    };
    
    const handleOfferBlur = (e) => {
        const value = e.target.value;
        // Ensure input is at least 1 when the input loses focus
        if (value === '' || parseFloat(value) < 1) {
            setOffer(1);
        } else {
            // Set the offer to the valid numeric value with up to two decimal places
            setOffer(parseFloat(value).toFixed(2));
        }
    };

    const handleResultClick = (index) => {
        setCurrentProduct(searchResults[index]);
        setImages(searchResults[index].images);
        setImageIndex(0);
        setDescription(searchResults[index].description.substring(0, 300) + "...");
        setSearchInputValue('');
        setSearchResults([]);
    }

    return (
        <>
            {showOverlay && (
                <div id="product-overlay" onClick={() => setShowOverlay(false)}>
                    <div id="product-overlay-content-wrapper">
                        <img id="product-overlay-image" src={images[imageIndex]} alt="No Image" />
                    </div>
                </div>
            )}
            <div id="product-header">
                <div id="product-logo-wrapper">
                    <button id="product-home-button">
                        <img id="product-logo" src={logo} alt="No Image" />
                    </button>
                </div>
                <div id="product-search-wrapper">
                    <img id="product-search-icon" src={searchIcon} alt="No Image" />
                    <input id="product-search-input" type="text" placeholder="Search" onChange={handleSearchInputChange} value={searchInputValue}></input>
                    {searchInputValue.length !== 0 && (
                        <div id="product-search-results-wrapper">
                            <div id="product-search-results">
                                {searchResults.map((result, index) => (
                                    <div key={index} className="product-search-result" onClick={() => handleResultClick(index)}>
                                        <div className="result-image-wrapper">
                                            <img className="result-image" src={currentProduct.images[0]} alt="No Image" />
                                        </div>
                                        <div className="result-content-wrapper">
                                            <p className="result-manufacturer">{result.manufacturer}</p>
                                            <p className="result-part-number">{result.partNumber}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div id="product-actions-wrapper">
                    <button id="cart-button">
                        <img id="cart-logo" src={cart} alt="No Image" />
                    </button>
                </div>
            </div>
            <div id="product">
                <div id="product-content-wrapper">
                    <div id="product-interaction-wrapper">
                        <div id="product-image-wrapper">
                            <img id="product-image" src={images[imageIndex]} alt="No Image" />
                            <div id="product-image-controls-overlay">
                                <button id="product-image-button-expand" onClick={() => setShowOverlay(true)}>
                                    <img id="product-expand-button-icon" src={expand}></img>
                                </button>
                                <div id="product-image-button-navigate">
                                    <div id="product-image-button-navigate-wrapper">
                                        <button id="product-image-button-left" onClick={() => {setImageIndex(0 !== imageIndex ? imageIndex - 1 : imageIndex)}}>
                                            <img id="product-left-button-icon" src={imageIndex === 0 ? leftSecondary : leftPrimary}></img>
                                        </button>
                                        <button id="product-image-button-right" onClick={() => {setImageIndex(images.length - 1 !== imageIndex ? imageIndex + 1 : imageIndex)}}>
                                            <img id="product-right-button-icon" src={imageIndex === images.length - 1 ? rightSecondary : rightPrimary}></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="product-details-wrapper">
                        <h3 id="product-manufacturer">NVIDIA</h3>
                        <h1 id="product-number">900-83668-0000-000</h1>
                        <div id="product-tags-wrapper">
                            <div className="product-tag green">
                                <img id="product-box-green-icon" src={boxGreen}></img>
                                <p className="product-tag-text">New In-Box</p>
                            </div>
                            <div className="product-tag blue">
                                <img id="product-box-icon" src={box}></img>
                                <p className="product-tag-text">Free Shipping</p>
                            </div>
                        </div>
                        <div id="product-description-wrapper">
                            <p>
                                {description}&nbsp;
                                <a onClick={() => setDescription(currentProduct.description.length === description.length ? currentProduct.description.substring(0, 300) + "..." : currentProduct.description)}>{currentProduct.description.length === description.length ? "show less." : "show more."}</a>
                            </p>
                        </div>
                        <div id="product-purchase-wrapper">
                            <div id="product-purchase-quantity-wrapper">
                                <div id="product-purchase-availability-wrapper">
                                    <p id="product-purchase-quantity-text">98 Available</p>
                                    <p id="product-purchase-quantity-sub-text">Last checked 2 hours ago</p>
                                </div>
                                <div id="product-purchase-quantity-input-wrapper">
                                    <div id="product-purchase-quantity-x-wrapper">
                                        <p id="product-purchase-quantity-x">Qty.</p>
                                    </div>
                                    <input id="product-purchase-quantity-input" type="text" value={quantity} onChange={handleQuantityInputChange} onBlur={handleQuantityBlur} />
                                </div>
                            </div>
                            {purchaseType === 1 && <div id="product-purchase-offer-wrapper">
                                <p id="product-purchase-offer-text">Offer per Unit</p>
                                <div id="product-purchase-offer-input-wrapper">
                                    <div id="product-purchase-offer-x-wrapper">
                                        <p id="product-purchase-offer-x">$</p>
                                    </div>
                                    <input id="product-purchase-offer-input" type="text" value={offer} onChange={handleOfferInputChange} onBlur={handleOfferBlur} />
                                </div>
                            </div>}
                            <form action="localhost:8080/stripe/create_checkout_session" method="POST">
                                <button id="product-purchase-button" onClick={handleBuyNow} type="submit">
                                    <p>{purchaseType === 0 ? "Buy Now" : "Make Offer"}</p>
                                    <p id="product-purchase-subtotal">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(totalInCents / 100)}</p>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="product-main-footer-wrapper">
                    <div id="product-main-footer-links-wrapper">
                        <button id="product-main-footer-link">About</button>
                        <button id="product-main-footer-link">Contact</button>
                        <button id="product-main-footer-link">Terms</button>
                        <button id="product-main-footer-link">Privacy</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Product;
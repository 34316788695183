import './style/Setting.css';
import React, { useState, useRef, useEffect } from 'react';
import Button from './Button';

const Setting = ({ props, setProps, name, enabledTitle, disabledTitle, enabledDescription, disabledDescription }) => {
    const [enablingOffers, setEnablingOffers] = useState(false);

    useEffect(() => {
        if (enablingOffers) {
            setTimeout(() => {
                setEnablingOffers(false);
                setProps({
                    ...props,
                    [name]: !props?.[name]});
            }, 2000);
        }
    }, [enablingOffers, name, props, setProps]);

    const handleButtonClick = (e) => {
        setEnablingOffers(true);
    };

    return (
        <div className="keystone-setting product-horizontal-container keystone-card pad-24 align-center space-between">
            <div className="product-vertical-container flex-1 v-gap-4">
                <p className="bold-14">{props?.offersEnabled ? enabledTitle :disabledTitle}</p>
                <p className="sf-pro size-13">{props?.offersEnabled ? enabledDescription : disabledDescription}</p>
            </div>
            <Button
                label={props?.offersEnabled ? "Disable Offers" : "Enable Offers"}
                loading={enablingOffers}
                enabled={props?.[name]}
                onClick={handleButtonClick}
                brand={true}
                />
        </div>
    );
};

export default Setting;
import './style/MarketVisuals.css';
import React from 'react';
import VerticalVisual from './VerticalVisual';
import Icon from './Icon';
import Bento from './Bento';

const MarketVisuals = ({ productData, stockIcon, stockStatus, stockRatio, priceIcon, priceStatus, priceRatio, allAspects, uniqueRatio }) => {
    const clampedStockRatio = Math.max(0, Math.min(1, stockRatio));
    const isStockOver = clampedStockRatio >= 0.5;

    const clampedPriceRatio = Math.max(0, Math.min(1, priceRatio));
    const isPriceOver = clampedPriceRatio >= 0.5;

    const clampedAspectsRatio = Math.max(0, Math.min(1, uniqueRatio));
    const isAspectsOver = clampedAspectsRatio >= 0.5;

    const createTooltipItemIfBoolean = (condition, label, yesValue, noValue) => {
        return typeof condition === "boolean" ? { label, value: condition ? yesValue : noValue } : null;
    };

    const createNumberTooltipItemIfExists = (label, value) => {
        return typeof value === "number" ? { label, value } : null;
    };

    const createTextTooltipItemIfExists = (label, value) => {
        return typeof value === "string" ? { label, value } : null;
    };

    return (
        <div className="product-vertical-container border-left">
            <div className="product-horizontal-container flex-1 space-between pad-24 pad-bot-none">
                <VerticalVisual ratio={stockRatio} icon={stockIcon} />
                <VerticalVisual ratio={priceRatio} icon={priceIcon} />
                <VerticalVisual ratio={uniqueRatio} icon={allAspects} />
            </div>
            <div className="product-horizontal-container space-between h-gap-24 pad-12">
                <Bento 
                    label="Stock Levels"
                    icon={stockIcon}
                    iconOnly={true}
                    iconSize={32}
                    value={stockStatus}
                    tooltipData={[
                        createNumberTooltipItemIfExists("Buy Now Quantity", productData.associated_part?.total_avail_instant),
                        createNumberTooltipItemIfExists("RFQ Quantity", productData.associated_part?.total_avail_rfq),
                        createNumberTooltipItemIfExists("Total Quantity", productData.associated_part?.total_avail),
                        createNumberTooltipItemIfExists("Average Quantity", productData.associated_part?.avg_total_avail)
                    ].filter(Boolean)}
                />
                <Bento 
                    icon={priceIcon}
                    iconOnly={true}
                    iconSize={32}
                    label="Price Competitiveness"
                    value={priceStatus}
                    tooltipData={[
                        createNumberTooltipItemIfExists("Estimated Distributor Unit Cost", productData.associated_part?.estimated_distributor_cost),
                        createNumberTooltipItemIfExists("Consumer Market Cost", productData.associated_part?.lowest_market_price),
                        createNumberTooltipItemIfExists("Number of Sellers", productData.associated_part?.sellers_with_priced_offers)
                    ].filter(Boolean)}
                />
                <Bento 
                    icon={allAspects}
                    iconOnly={true}
                    iconSize={32}
                    label="Unique Aspects"
                    value={productData.associated_part?.unique_aspects_count}
                    theme="percent"
                    themeValue={productData.associated_part?.unique_aspects_percent}
                    tooltipData={[
                        createTooltipItemIfBoolean(productData.associated_part?.rohs_compliant, "RoHS", "Compliant", "Not Compliant"),
                        createTooltipItemIfBoolean(productData.associated_part?.lead_free, "Lead Free", "Yes", "No"),
                        createTooltipItemIfBoolean(productData.associated_part?.reach_svhc, "Reach SVHC", "Yes", "No"),
                        createTooltipItemIfBoolean(productData.associated_part?.wide_temp_range, "Wide Temperature Range", "Yes", "No"),
                        createTooltipItemIfBoolean(productData.associated_part?.radiation_hardened, "Radiation Hardened", "Yes", "No"),
                        createTooltipItemIfBoolean(productData.associated_part?.military_standard, "Military Standard", productData.associated_part?.military_standard_value, "No"),
                        createTextTooltipItemIfExists("Lead Time", productData.associated_part?.estimated_factory_lead_weeks ? `${productData.associated_part?.estimated_factory_lead_weeks} Weeks` : null),
                        createTextTooltipItemIfExists("Lifecycle Status", productData.associated_part?.lifecycle_status)
                    ].filter(Boolean)}
                />
            </div>
        </div>
    );
};

export default MarketVisuals;
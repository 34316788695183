import Icon from './Icon';
import './style/InventoryHeaderColumn.css';
import React, { useState, useRef, useEffect } from 'react';
import DownArrow from '../../media/down-arrow-thick@3x.png';
import Visible from '../../media/visible@3x.png';

const InventoryHeaderColumn = ({ label, userPreferences, setUserPreferences }) => {
    const [showVisible, setShowVisible] = useState(false);
    const [color, setColor] = useState('#b9bfd6');

    useEffect(() => {
        if (userPreferences?.sortBy.includes(label)) {
            setColor('#dd780d');
        } else {
            setColor('#b9bfd6');
        }
    }, [label, userPreferences?.sortBy]);
    
    return (
        <div className={`keystone-inventory-header-column h-gap-16 space-between align-center ${userPreferences?.hiddenColumns?.includes(label) && "flex-0"}`} onMouseEnter={() => setShowVisible(true)} onMouseLeave={() => setShowVisible(false)}>
            <div className="product-horizontal-container h-gap-4 align-center">
                {!userPreferences?.hiddenColumns?.includes(label) && <p className="keystone-column-header">{label}</p>}
                <button className={showVisible || userPreferences?.hiddenColumns?.includes(label) ? 'visibility-icon' : 'visibility-icon hidden'} onClick={() => {
                    if (userPreferences?.hiddenColumns?.includes(label)) {
                        setUserPreferences({ ...userPreferences, hiddenColumns: userPreferences?.hiddenColumns?.filter(column => column !== label) });
                    } else {
                        setUserPreferences({ ...userPreferences, hiddenColumns: [userPreferences?.hiddenColumns, label] });
                    }
                }}>
                    <Icon icon={Visible} size="16" color="#b9bfd6" hoverColor="#000" />
                </button>
            </div>
            {!userPreferences?.hiddenColumns?.includes(label) && <button className={userPreferences?.sortBy.includes("ascending") && userPreferences?.sortBy.includes(label) ? "rotate" : label !== userPreferences?.sortBy && "disabled"} onClick={() => {
                    setUserPreferences({ ...userPreferences, sortBy: userPreferences?.sortBy === label ? `${label}-ascending` : label });
                }
            }>
                <Icon icon={DownArrow} size="12" color={color} />
            </button>}
        </div>
    );
};

export default InventoryHeaderColumn;
import './style/Modal.css';
import React, { useState, useRef, useEffect } from 'react';

import UploadFlow from './UploadFlow';
import InventoryAccessFlow from './InventoryAccessFlow';
import CreateAccount from './CreateAccount';
import TermsAgreement from './TermsAgreement';

const Modal = ({ props, setProps, showModal, modalType, setModalType, setShowModal, handleFileUpload, file, fileName, removeFile, fileInputRef, accessToken, validationErrors, fileUploading, uploadedInventory, commitInventory }) => {
    return showModal && (
        <div id="keystone-modal" onClick={(e) => {if (e.target.id === "keystone-modal") { setShowModal(false); }}}>
            {modalType === "upload-flow" && (
                <UploadFlow
                    setShowModal={setShowModal}
                    uploadedInventory={uploadedInventory}
                    file={file}
                    fileInputRef={fileInputRef}
                    fileName={fileName}
                    removeFile={removeFile}
                    handleFileUpload={handleFileUpload}
                    validationErrors={validationErrors}
                    commitInventory={commitInventory}
                />
            )}
            {modalType === "inventory-access" && (
                <InventoryAccessFlow
                    setShowModal={setShowModal} />
            )}
            {modalType === "create-account" && (
                <CreateAccount
                    props={props}
                    setProps={setProps}
                    setShowModal={setShowModal}
                    setModalType={setModalType} />
            )}
            {modalType === "tos" && (
                <TermsAgreement
                    props={props}
                    setProps={setProps}
                    setShowModal={setShowModal} />
            )}
        </div>
    );
};

export default Modal;
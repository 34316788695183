import './style/Divider.css';
import React, { useState, useRef, useEffect } from 'react';

const Divider = ({ label }) => {
    return (
        <div className="keystone-divider width-100 relative">
            {label && <p className="sf-mono">{label}</p>}
        </div>
    );
};

export default Divider;
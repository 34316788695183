import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { v4 as UUID } from 'uuid';
import { useAuth0 } from "@auth0/auth0-react";
import logoGrey from './media/materialize-logo@3x.png';
import logo from './media/materialize-logo-black@3x.png';

import Landing from './page/Landing';
import Store from './page/Store';
import Login from './page/Login';
import getBrowserData from './Track';
import User from './component/User';
import Keystone from './page/Keystone';
import Assessment from './component/Assessment';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FullViewIframe from './component/FullViewIframe';

const CustomComponent = () => <div>Custom Component</div>;

function App() {
  const [sessionId, setSessionId] = useState(null);
  const [landing, setLanding] = useState(true);
  const [browserData, setBrowserData] = useState({});
  const { isAuthenticated, user, error, logout, getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect } = useAuth0();
  // Add state for managing login errors
  const [loginError, setLoginError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [gettingAccessToken, setGettingAccessToken] = useState(false);
  const [pdfView, setPdfView] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [embedLink, setEmbedLink] = useState(null);
  const [isCustomer, setIsCustomer] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showStore, setShowStore] = useState(false);

  // Custom login function
  const handleLogin = async () => {
    try {
      let result = await loginWithRedirect({
        authorizationParams: {
          connection: 'google-oauth2',
          magic_link_code: new URLSearchParams(window.location.search).get('mc') || 'FOREVAN',
        }
      });
      if (!result || error) {
        console.log("Login failed:", error);
        setLoginError(error); // Clear any previous errors on successful login
      }
    } catch (e) {
      console.log("Login failed:", error);
      console.log("Exception for login: ", e);
      setLoginError(error);
    }
  };

  useEffect(() => {
    const fetchEmbedLink = async () => {
      if (!accessToken) return; // Ensure there is an access token
      setShowLoader(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/hub/embed-link`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });
        const result = await response.json();
        setIsCustomer(true);
        setEmbedLink(result.link); // Set the URL in state
      } catch (error) {
        console.error("Failed to fetch embed link:", error);
        setIsCustomer(false);
      }
      setShowLoader(false);
    };

    if (isAuthenticated) {
      fetchEmbedLink();
    } else if (error) {
      console.error('Authentication Error:', error);
    }
  }, [isAuthenticated, accessToken, error]); // Re-fetch when these dependencies change

  const handleLogout = () => {
    sessionStorage.removeItem('sessionId');
    setShowLoader(false);
    logout();
  };

  useEffect(() => { 
    console.log(sessionId);
  }, [sessionId]);

  const handleShowLanding = async () => {
    window.location.href = '/';
    setLanding(true);
  }

  const updateUserAndGetSessionId = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ sessionId, user }),
        });
        await response.json();
    } catch (error) {
        // console.log(error);
    }
  };

  const getAccessToken = async () => {
    if (gettingAccessToken) return;

    setGettingAccessToken(true);

    try {
      const local = window.location.origin.includes('local') ? true : false;

      const opts = {
        authorizationParams: {
          audience: local ? 'https://dev-s6tdx5li201sdtoo.us.auth0.com/api/v2/' : 'https://trymaterialize.us.auth0.com/api/v2/',
          scope: "openid profile email offline_access"
        },
      };
  
      let accessToken = await getAccessTokenSilently(opts);
      setAccessToken(accessToken);
    } catch (error) {
      // console.log(error);
    }

    setGettingAccessToken(false);
  };

  useEffect(() => {
    if (accessToken) {
      updateUserAndGetSessionId();
    }
  }, [accessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      if (user && user.email && user.email !== '' && user.email_verified) {
        setUserEmail(user.email);
      }
      getAccessToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setBrowserData(getBrowserData());
    const sessionIdFromStorage = sessionStorage.getItem('sessionId');

    if (sessionIdFromStorage && sessionIdFromStorage !== null) {
      setSessionId(sessionIdFromStorage);
    } else {
      const newSessionId = UUID();
      setSessionId(newSessionId);
      sessionStorage.setItem('sessionId', newSessionId);
    }
  }, []);

  const handlePdfView = () => {
    setPdfView(!pdfView);
  }

  return (
    <Router>
      <div id="app" className={pdfView ? "pdf-view" : (isAuthenticated && isCustomer) ? "sidebar" : ""}>
        
        <Routes>
          <Route path="/assessment" element={<>
            {!pdfView &&
              <div id="header">
                <img src={logo} id="logo-new" alt="logo" onClick={handleShowLanding} />
                {!pdfView && <User sessionId={sessionId} isAuthenticated={isAuthenticated} login={handleLogin} user={user} logout={handleLogout} label="Save your progress"/>}
              </div>
            }
            <Assessment pdfView={pdfView} login={handleLogin} sessionId={sessionId} userEmail={userEmail} accessToken={accessToken} />
            {landing && !pdfView && <Landing />}
          </>} />
          {<Route path="/magiclink" element={
            <Keystone></Keystone>
          } />}
          <Route path="/" element={
            showStore ?
              <Store></Store> :
              <>
                {!showLoader ?
                  <>
                  {(isAuthenticated && isCustomer) && <FullViewIframe url={embedLink} handleLogout={handleLogout} />}
                  {!pdfView &&
                    <div id="header" className={(isAuthenticated && isCustomer) ? "sidebar" : ""}>
                      {!pdfView && (isAuthenticated && isCustomer) && <User sessionId={sessionId} isAuthenticated={isAuthenticated} login={handleLogin} user={user} logout={handleLogout} label=""/>}
                      {(!isAuthenticated || (!isCustomer && isAuthenticated)) && 
                        <img src={logo} id="logo-new" alt="logo" onClick={handleShowLanding} />
                      }
                    </div>
                  }

                  {/* {landing && !pdfView && isAuthenticated && <Landing />} */}
                  {landing && !pdfView && (!isAuthenticated || (!isCustomer && isAuthenticated)) && <Login login={handleLogin} isCustomer={isCustomer} isAuthenticated={isAuthenticated} />}
                  </>
                : <div id="loader-wrapper"><div className="loader"></div></div>
                }
              </>
          } />
        </Routes>
        <div id="pdf-view" onClick={handlePdfView}></div>
      </div>
    </Router>
  );
}

export default App;
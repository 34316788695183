import './style/ProductDetails.css';
import React, { useState, useRef, useEffect } from 'react';
import ChipRounded from './ChipRounded';
import Icon from './Icon';
import noStock from '../../media/no-stock@3x.png';
import underStocked from '../../media/under-stocked@3x.png';
import averageStock from '../../media/average-stock@3x.png';
import overStocked from '../../media/over-stocked@3x.png';
import overCosted from '../../media/over-costed@3x.png';
import highlyCompetitive from '../../media/highly-competitive@3x.png';
import competitive from '../../media/competitive@3x.png';
import moderatelyCompetitive from '../../media/moderately-competitive@3x.png';
import lessCompetitive from '../../media/less-competitive@3x.png';
import shortLeadTimes from '../../media/short-lead-times@3x.png';
import longLeadTimes from '../../media/long-lead-times@3x.png';
import notManufactured from '../../media/not-manufactured@3x.png';
import stillManufactured from '../../media/still-manufactured@3x.png';
import unknown from '../../media/unknown@3x.png';
import allAspects from '../../media/all-aspects@3x.png';
import hourglassEmpty from '../../media/hourglass-empty@3x.png';
import link from '../../media/link@3x.png';
import Offers from './Offers';
import MarketVisuals from './MarketVisuals';
import { formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';

const ProductDetails = ({ props, setProps, demoCompleted, productData, setShowModal, setModalType }) => {
    const [productQuantity, setProductQuantity] = useState(1);
    const [productUnitCost, setProductUnitCost] = useState(0);

    const [productTotalCost, setProductTotalCost] = useState(0);
    const [stockIcon, setStockIcon] = useState(averageStock);
    const [stockStatus, setStockStatus] = useState("Average Stock");
    const [stockRatio, setStockRatio] = useState(0);
    const [priceIcon, setPriceIcon] = useState(averageStock);
    const [priceStatus, setPriceStatus] = useState("Moderately Competitive");
    const [priceRatio, setPriceRatio] = useState(0);
    const [productionIcon, setProductionIcon] = useState(stillManufactured);
    const [productionStatus, setProductionStatus] = useState("Still Manufactured");
    
    useEffect(() => {
        if (productData) {
            setProductUnitCost(productData.minimum_price);
            setProductQuantity(productData.quantity);
            setProductTotalCost(productData.minimum_price * productData.quantity);
        }
    }, [productData]);

    useEffect(() => {
        if (productData) {
            /* STOCK DETAILS */
            const stockLevel = productData.associated_part?.stock_level;
            setStockIcon(
                stockLevel === "NONE" ? noStock :
                stockLevel === "OVER"  ? overStocked :
                stockLevel === "UNDER" ? underStocked :
                stockLevel === "AVERAGE" ? averageStock :
                unknown);

            setStockStatus(
                stockLevel === "NONE" ? "No Stock" :
                stockLevel === "OVER"  ? "High Stock" :
                stockLevel === "UNDER" ? "Low Stock" :
                stockLevel === "AVERAGE" ? "Average Stock" :
                "Not Found");

            const averageQuantity = Math.max(0, productData.associated_part?.avg_total_avail);
            const actualQuantity = Math.max(0, productData.associated_part?.total_avail);
            let ratio = averageQuantity / actualQuantity;

            if (averageQuantity === 0 && actualQuantity === 0) {
                ratio = 1;
            } else if (actualQuantity === 0) {
                ratio = 1;
            } else {
                ratio = 1 - (1 / (1 + ratio));
            }

            setStockRatio(ratio);

            /* PRICE DETAILS */
            const costAboveMarket = productData.minimum_price > productData.associated_part?.lowest_market_price;
            const costBelowDistribution = productData.minimum_price < productData.associated_part?.estimated_distributor_cost;

            const costBelowSpreadOneThird = productData.minimum_price < productData.computed?.spread_one_third;
            const costAboveSpreadTwoThird = productData.minimum_price > productData.computed?.spread_two_third;
            const costBetweenSpread = productData.minimum_price  >= productData.computed?.spread_one_third && productData.minimum_price  <= productData.computed?.spread_two_third;

            setPriceIcon(
                costAboveMarket ? overCosted :
                costBelowDistribution ? highlyCompetitive : 
                costBelowSpreadOneThird ? competitive : 
                costAboveSpreadTwoThird ? lessCompetitive :
                costBetweenSpread ? moderatelyCompetitive :
                unknown
            );

            setPriceStatus(
                costAboveMarket ? "Over Costed" :
                costBelowDistribution ? "Highly Competitive" : 
                costBelowSpreadOneThird ? "Competitive" : 
                costAboveSpreadTwoThird ? "Less Competitive" :
                costBetweenSpread ? "Moderately Competitive" :
                "Not Found"
            );

            let competitivenessScore = 0;

            if (productData.minimum_price <= 0 || productData.associated_part?.estimated_distributor_cost <= 0) {
                competitivenessScore = 1;
            } else {
                // Calculate the ratio of estimated distributor cost to product unit cost
                const ratio = productData.associated_part?.estimated_distributor_cost / productData.minimum_price;

                // Use a non-linear function to compute the competitiveness score
                competitivenessScore = 1 - (1 / (1 + ratio));

                // Ensure the competitiveness score is between 0 and 1
                competitivenessScore = Math.max(0, Math.min(1, competitivenessScore));
            }

            setPriceRatio(competitivenessScore);
            
            /* PRODUCTION DETAILS */
            const hasLongLeadTime = productData.associated_part?.estimated_factory_lead_weeks && productData.associated_part?.estimated_factory_lead_weeks > 4;
            const hasShortLeadTime = productData.associated_part?.estimated_factory_lead_weeks && productData.associated_part?.estimated_factory_lead_weeks <= 4;
            const isInProduction = productData.associated_part?.lifecycle_status && productData.associated_part?.lifecycle_status === "ACTIVE" ? true : productData.associated_part?.lifecycle_status === "INACTIVE" ? false : null;
            
            // TODO: ADD TO ICON AND STATUS SET
            const isEndingProduction = productData.associated_part?.lifecycle_status && (productData.associated_part?.lifecycle_status === "EOL" || productData.associated_part?.lifecycle_status === "NRND");

            setProductionIcon(
                isEndingProduction ? hourglassEmpty :
                hasLongLeadTime ? longLeadTimes :
                hasShortLeadTime ? shortLeadTimes :
                isInProduction ? stillManufactured :
                isInProduction === false ? notManufactured :
                unknown
            )

            setProductionStatus(
                isEndingProduction ? "Ending Production" :
                hasLongLeadTime ? "Long Lead Times" :
                hasShortLeadTime ? "Short Lead Times" :
                isInProduction ? "Still Manufactured" :
                isInProduction === false ? "Not Manufactured" :
                "Not Found"
            )
        }
    }, [productData, productUnitCost, productQuantity]);

    return (
        <div id="content-body" className="product-example">
            <div id="product-example-header" className="product-vertical-container">
                <div className="product-vertical-container h-gap-24 align-center">
                    <div className="product-horizontal-container flex-1 width-100 h-gap-24 align-start relative">
                        <div className="product-vertical-container flex-1 keystone-card">
                            <div className="product-vertical-container v-gap-16 pad-24">
                                <ChipRounded label="Your Stock" />
                                <div className="product-vertical-container">
                                    <p className="product-manufacturer">{productData.manufacturer}</p>
                                    <p className="product-mpn">{productData.mpn}</p>
                                    <p className="product-description">{productData.description}</p>
                                    {/* <p className="product-series">{productData.condition}</p> */}
                                </div>
                            </div>
                            <div className="product-vertical-container flex-1 width-100 keystone-card-footer">
                                <div className="product-horizontal-container h-gap-16">
                                    <div className="product-vertical-container flex-1">
                                        <p className="bold-12">Quantity on Hand</p>
                                        {demoCompleted ? <p className="total-cost">{productQuantity}</p> : <input type="number" className="simple product-quantity" placeholder="Quantity" value={productQuantity} onChange={(e) => setProductQuantity(Number(e.target.value))} />}
                                    </div>
                                    <div className="product-vertical-container flex-1">
                                        <p className="bold-12">Unit Cost</p>
                                        {demoCompleted ? <p className="total-cost">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productUnitCost)}</p> : <input type="number" className="simple product-price" placeholder="Unit Cost" value={productUnitCost} onChange={(e) => setProductUnitCost(Number(e.target.value))} />}
                                    </div>
                                    <div className="product-vertical-container flex-1">
                                        <p className="bold-12">Total Cost</p>
                                        <p className="total-cost">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productUnitCost * productQuantity)}</p>
                                    </div>
                                </div>
                            </div>
                            <Offers
                                props={props}
                                setProps={setProps}
                                productData={productData}
                                productQuantity={productQuantity}
                                productUnitCost={productUnitCost}
                                productTotalCost={productTotalCost}
                                setShowModal={setShowModal}
                                setModalType={setModalType}
                            />
                        </div>
                        <div id="linked-part">
                            <Icon icon={link} color="#000" size="32" />
                        </div>
                        <div className="product-vertical-container flex-1 keystone-card">
                            <div className="product-horizontal-container flex-1">
                                <div className="product-vertical-container flex-1">
                                    <div className="product-horizontal-container pad-24 space-between">
                                        <div className="product-vertical-container v-gap-16">
                                            <ChipRounded label="Market Data" />
                                            <div className="product-vertical-container">
                                                <p className="product-manufacturer"><a href={productData.associated_part?.manufacturer_url} target="_blank" rel="noreferrer">{productData.associated_part?.manufacturer}</a></p>
                                                <p className="product-mpn">{productData.associated_part?.mpn}</p>
                                                <p className="product-description">{productData.associated_part?.short_description}</p>
                                                {/* <p className="product-description">{productData.associated_part?.category}</p>
                                                {productData.series && <p className="product-category">{productData.associated_part?.series}</p>} */}
                                            </div>
                                        </div>
                                        {/* <div className="product-vertical-container">
                                            <img src={analysis} alt="Analysis" className="img-48" />
                                        </div> */}
                                    </div>
                                    <div className="product-vertical-container flex-1 width-100 keystone-card-footer">
                                        <div className="product-horizontal-container h-gap-16">
                                            <div className="product-vertical-container flex-1">
                                                <p className="bold-12">Quantity Available</p>
                                                <p className="total-cost">{Math.max(0, productData.associated_part?.total_avail)}</p>
                                            </div>
                                            <div className="product-vertical-container flex-1">
                                                <p className="bold-12">Market Price</p>
                                                <p className="total-cost">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productData.associated_part?.lowest_market_price)}</p>
                                            </div>
                                            <div className="product-vertical-container flex-1">
                                                <p className="bold-12">Distributor Cost</p>
                                                <p className="total-cost">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productData.associated_part?.estimated_distributor_cost)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <MarketVisuals productData={productData} stockIcon={stockIcon} stockStatus={stockStatus} stockRatio={stockRatio} priceIcon={priceIcon} priceStatus={priceStatus} priceRatio={priceRatio} allAspects={allAspects} uniqueRatio={productData?.associated_part?.unique_aspects_percent}/>
                            </div>
                            <div id="strategy-suggestion" className="product-vertical-container v-gap-16">
                                <ChipRounded label="Time To Exceed Cost" />
                                {(productData.computed?.spread_two_third * productQuantity) < (productUnitCost * productQuantity) ? (
                                    <div className="product-vertical-container flex-1">
                                        <p className="bold-12">Estimation</p>
                                        <p className="strategy-label">Unlikely to receive offers in less than 6 months at current unit cost.</p>
                                    </div>
                                ) : productUnitCost <= productData.computed?.estimated_distributor_cost_half ? (
                                    <div className="product-horizontal-container flex 1">
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimation</p>
                                            <p className="strategy-label">1 month to receive an offer that exceeds unit cost.</p>
                                        </div>
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimated Return</p>
                                            <p className="value-currency">{formatToCurrency(productData.computed?.estimated_distributor_cost_half * productQuantity)}</p>
                                            <p className="strategy-label">Up to {formatToCurrencyExpanded(productData.computed?.estimated_distributor_cost_half)} per unit</p>
                                            <p className="percent-tag">{(((productData.computed?.estimated_distributor_cost_half * productQuantity) / productTotalCost) * 100).toFixed(0)}%</p>
                                        </div>
                                    </div>
                                ) : productUnitCost <= productData.computed?.spread_one_third ? (
                                    <div className="product-horizontal-container flex 1">
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimation</p>
                                            <p className="strategy-label">1-3 months to receive an offer that exceeds unit cost.</p>
                                            {
                                                productData.associated_part?.stock_level?.includes("OVER" || "AVERAGE") &&
                                                productData.associated_part?.estimated_distributor_cost < productUnitCost &&
                                                productData.associated_part?.unique_aspects_percent <= 0.5 && (
                                                    <p className="strategy-label">Reassess unit cost if urgent</p>
                                                )
                                            }
                                        </div>
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimated Return</p>
                                            <p className="value-currency">{formatToCurrency(productData.computed?.spread_one_third * productQuantity)}</p>
                                            <p className="strategy-label">Up to {formatToCurrencyExpanded(productData.computed?.spread_one_third)} per unit</p>                           
                                            <p className="percent-tag">{(((productData.computed?.spread_one_third * productQuantity) / productTotalCost) * 100).toFixed(0)}%</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="product-horizontal-container flex 1">
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimation</p>
                                            <p className="strategy-label">3-6+ months to receive an offer that exceeds unit cost.</p>
                                            {
                                                productData.associated_part?.stock_level?.includes("OVER" || "AVERAGE") &&
                                                productData.associated_part?.estimated_distributor_cost < productUnitCost &&
                                                productData.associated_part?.unique_aspects_percent <= 0.5 && (
                                                    <p className="strategy-label">Reassess unit cost if urgent</p>
                                                )
                                            }
                                        </div>
                                        <div className="product-vertical-container flex-1">
                                            <p className="bold-12">Estimated Return</p>
                                            <p className="value-currency">{formatToCurrency(productData.computed?.spread_two_third * productQuantity)}</p>
                                            <p className="strategy-label">More than {formatToCurrencyExpanded(productData.computed?.spread_two_third)} per unit</p>
                                            <p className="percent-tag">{(((productData.computed?.spread_two_third * productQuantity) / productTotalCost) * 100).toFixed(0)}%</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!demoCompleted && <div id="inventory-upload-cta" className="product-vertical-container gradient-border">
                <p id="strategy-suggestion-label">Analyze Your Unneeded Inventory</p>
                <div className="product-horizontal-container flex-1 align-center">
                    <p className="strategy-label flex-1">Each inventory item is analyzed granularly to determine its possible resale value, arming you with the data you need to make decisions on offers that meet or exceed your reclamation goals.</p>
                    <button className="keystone-primary-button" onClick={() => setShowModal(true)}>Upload File</button>
                </div>
            </div>}
        </div>
    );
};

export default ProductDetails;
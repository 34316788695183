import './style/TabGroup.css';
import React, { useState, useRef, useEffect } from 'react';
import Tab from './Tab';

const TabGroup = ({ props, setProps, tabs }) => {

    useEffect(() => {
        if (!props?.[props?.page + "Tab"]) {
            setProps({ ...props, [props.page + "Tab"]: 0 });
        }
    }, []);
    
    return (
        <div className="keystone-tab-group product-horizontal-container h-gap-8">
            {tabs.map((tab, index) => {
                return <Tab key={index} index={index} props={props} setProps={setProps} label={tab} active={props?.[props?.page + "Tab"] === index} />
            })}
        </div>
    );
};

export default TabGroup;
import './style/ScoreBar.css';
import React, { useState, useRef, useEffect } from 'react';

const ScoreBar = ({ score, gold, invert }) => {
    const [fillWidth, setFillWidth] = useState(0);
  
    useEffect(() => {
      const fillAnim = setTimeout(() => {
        setFillWidth(score * 100);
      }, 100); // Delays the beginning of the animation slightly for effect
  
      return () => clearTimeout(fillAnim); // Cleanup in case of unmount
    }, [score]);
    
    return (
        <div className={`keystone-row-data ${invert && 'invert'}`}>
            <div className="bar-background">
            <div className="bar-fill" style={{ width: `${fillWidth}%`, backgroundColor: '#4caf50' }} />
            </div>
        </div>
    );
};

export default ScoreBar;
import './style/UploadFlow.css';
import React, { useState, useRef, useEffect } from 'react';
import HeaderVisual from './HeaderVisual';
import CellPreview from './CellPreview';

const UploadFlow = ({ setShowModal, uploadedInventory, file, fileInputRef, fileName, removeFile, handleFileUpload, validationErrors, commitInventory }) => {
    const [headerData, setHeaderData] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [dropHover, setDropHover] = useState(false);
    const [hasHeaderErrors, setHasHeaderErrors] = useState(false);
    const [headerErrors, setHeaderErrors] = useState([]);
    const [rowErrorsWithData, setRowErrorsWithData] = useState([]);

    useEffect(() => {
        setHeaderData(uploadedInventory?.file_data?.sheets?.[0]?.parsed_header_row?.data);
        setRowsData(uploadedInventory?.file_data?.sheets?.[0]?.rows);
    }, [uploadedInventory]);

    useEffect(() => {
        if (validationErrors?.length > 0) {
            const headerValidationErrors = validationErrors.filter(error => !error.row_number)?.[0] || null;
            if (headerValidationErrors) {
                setHasHeaderErrors(true);
                setHeaderErrors(headerValidationErrors.errors);
            } else {
                setHasHeaderErrors(false);
                setHeaderErrors([]);
            }

            const rowValidationErrors = validationErrors.filter(error => error.row_number !== null);

            const rowValidationErrorsWithErrorData = rowValidationErrors.map(error => {
                const rowData = uploadedInventory?.file_data?.sheets?.find(sheet => sheet.name === error.sheet_name)?.rows?.find(item => item.row_number === error.row_number)?.data;
                return {
                    ...error,
                    rowData
                };
            });

            setRowErrorsWithData(rowValidationErrorsWithErrorData);
        }
    }, [validationErrors]);

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            console.log(file);
            handleFileUpload({ target: { files: [file] } });
        }
        setDropHover(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(false);
    }

    return (
        <div className="keystone-upload-flow modal-wrapper">
            <div className="modal-header product-horizontal-container space-between align-center">
                <button id="close-modal" style={{opacity: '0'}}>X</button>
                <button className="modal-header-button active">Upload</button>
                <button className="modal-header-button">Review</button>
                <button className="modal-header-button">Goal</button>
                <button id="close-modal" onClick={() => setShowModal(false)}>X</button>
            </div>
            <div className="divider"></div>
            {!file && <div 
                className={dropHover ? "product-vertical-container drop-zone hover" : "product-vertical-container drop-zone"}
                onDrop={handleDrop} 
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDragEnter={handleDragEnter}>
                <p className="sf-pro center-text drag-drop-text">
                    Drag and drop or&nbsp;
                    <label htmlFor="file-upload" className="file-label">
                        select to upload 
                        <input 
                            type="file"
                            id="file-upload" 
                            className="file-input" 
                            accept=".csv, .xlsx" 
                            onChange={handleFileUpload}
                            ref={fileInputRef} />
                    </label>
                    &nbsp;a .csv or .xlsx file of your excess inventory.
                </p>
                {file &&
                    <div id="uploaded-file-wrapper">
                        <p>{fileName}</p>
                        <button id="remove-file" onClick={removeFile}>Remove</button>
                    </div>
                }
                <div className="product-vertical-container">
                    <p className="sf-pro center-text modal-disclaimer"><b>Note:</b> We suggest including the following columns for the best experience. If you have a column but the name varies, our system will attempt to reconcile it.</p>
                </div>
                <div className="product-horizontal-container h-gap-16 flex-wrap justify-center">
                    <HeaderVisual label="Unique ID" tooltip="Identifier that you use internally that does not change." fancy={true} />
                    <HeaderVisual label="Warehouse Region" tooltip="Region the item is stored in." fancy={true} />
                    <HeaderVisual label="Manufacturer" tooltip="Original equipment manufacturer." fancy={true} />
                    <HeaderVisual label="MPN" tooltip="Manufacturer's part number." fancy={true} />
                    <HeaderVisual label="Quantity" tooltip="Amount on hand at location." fancy={true} />
                    <HeaderVisual label="Quantity Type" tooltip="How the quantity is measured (units, weight, feet, etc.)" fancy={true} />
                    <HeaderVisual label="Warehouse Country" tooltip="Country the item is stored in." fancy={true} />
                    <HeaderVisual label="Condition" tooltip="Condition the specific items are in." fancy={true} />
                    <HeaderVisual label="Minimum Price" tooltip="Lowest price deemed acceptable per unit." fancy={true} />
                    <HeaderVisual label="Description" tooltip="Additional details about the item." fancy={true} />
                </div>
            </div>}
            {file && (
                <div id="review-container" className="product-vertical-container v-gap-32">
                    {/* <div className="product-horizontal-container flex-1">
                        {rowsData?.length > 0 && rowsData[1].data.map((cell, index) => (
                            <p className="bold sf-pro">{cell}</p>
                        ))}
                    </div> */}
                    <div className="product-vertical-container v-gap-8">
                        <p className="bold sf-pro">Required Fields</p>
                        <div id="review-headers-container" className="product-horizontal-container v-gap-16 h-gap-16 flex-wrap">
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"ID"}
                                    value={uploadedInventory?.header_mappings?.["Unique ID"] !== null ? uploadedInventory?.header_mappings?.["Unique ID"] : "Not Found"}
                                    tooltip="Identifier that you use internally that does not change."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Unique ID") ? "negative" : uploadedInventory?.header_mappings?.["Unique ID"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Region"}
                                    value={uploadedInventory?.header_mappings?.["Warehouse Region"] !== null ? uploadedInventory?.header_mappings?.["Warehouse Region"] : "Not Found"}
                                    tooltip="Region the item is stored in."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Warehouse Region") ? "negative" : uploadedInventory?.header_mappings?.["Warehouse Region"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"MFG"}
                                    value={uploadedInventory?.header_mappings?.["Manufacturer"] !== null ? uploadedInventory?.header_mappings?.["Manufacturer"] : "Not Found"}
                                    tooltip="Original equipment manufacturer."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Manufacturer") ? "negative" : uploadedInventory?.header_mappings?.["Manufacturer"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"MPN"}
                                    value={uploadedInventory?.header_mappings?.["MPN"] !== null ? uploadedInventory?.header_mappings?.["MPN"] : "Not Found"}
                                    tooltip="Manufacturer's part number."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "MPN") ? "negative" : uploadedInventory?.header_mappings?.["MPN"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Qty"}
                                    value={uploadedInventory?.header_mappings?.["Quantity"] !== null ? uploadedInventory?.header_mappings?.["Quantity"] : "Not Found"}
                                    tooltip="Amount on hand at location."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Quantity") ? "negative" : uploadedInventory?.header_mappings?.["Quantity"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                        </div>
                    </div>
                    <div className="product-vertical-container v-gap-8">
                        <p className="bold sf-pro">Optional Fields</p>
                        <div id="review-headers-container" className="product-horizontal-container v-gap-16 h-gap-16 flex-wrap">
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Qty Type"}
                                    value={uploadedInventory?.header_mappings?.["Quantity Type"] !== null ? uploadedInventory?.header_mappings?.["Quantity Type"] : "Not Found"}
                                    tooltip="How the quantity is measured (units, weight, feet, etc.)"
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Quantity Type") ? "negative" : uploadedInventory?.header_mappings?.["Quantity Type"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Country"}
                                    value={uploadedInventory?.header_mappings?.["Warehouse Country"] !== null ? uploadedInventory?.header_mappings?.["Warehouse Country"] : "Not Found"}
                                    tooltip="Country the item is stored in." 
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Warehouse Country") ? "negative" : uploadedInventory?.header_mappings?.["Warehouse Country"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Condition"}
                                    value={uploadedInventory?.header_mappings?.["Condition"] !== null ? uploadedInventory?.header_mappings?.["Condition"] : "Not Found"}
                                    tooltip="Condition the specific items are in."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Condition") ? "negative" : uploadedInventory?.header_mappings?.["Condition"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Min Price"}
                                    value={uploadedInventory?.header_mappings?.["Minimum Price"] !== null ? uploadedInventory?.header_mappings?.["Minimum Price"] : "Not Found"}
                                    tooltip="Lowest price deemed acceptable per unit."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Minimum Price") ? "negative" : uploadedInventory?.header_mappings?.["Minimum Price"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                            <div className="product-horizontal-container">
                                <HeaderVisual
                                    label={"Description"}
                                    value={uploadedInventory?.header_mappings?.["Description"] !== null ? uploadedInventory?.header_mappings?.["Description"] : "Not Found"}
                                    tooltip="Additional details about the item."
                                    fancy={false}
                                    status={hasHeaderErrors && headerErrors?.some(error => error.field === "Description") ? "negative" : uploadedInventory?.header_mappings?.["Description"] !== null ? "positive" : "neutral"}
                                    headerData={headerData} />
                            </div>
                        </div>
                    </div>
                    <div className="product-vertical-container v-gap-8">
                        <p className="bold sf-pro">Issues Found</p>
                        <div className="product-vertical-container v-gap-16">
                            {rowErrorsWithData && rowErrorsWithData.map((errorParent, index) => (
                                <div className="product-horizontal-container">
                                    <div className="product-vertical-container align-center">
                                        <p className="sf-mono cell-preview-column-name">Row</p>
                                        <p className="sf-mono cell-preview-value center-text cell-preview-row-value">{errorParent.row_number}</p>
                                        <p className="sf-mono cell-preview-message" style={{opacity: '0'}}>0</p>
                                    </div>
                                    {/* <div className="product-vertical-container align-center">
                                        <p className="sf-mono cell-preview-column-name">Sheet</p>
                                        <p className="sf-mono cell-preview-value center-text cell-preview-row-value">{errorParent.sheet_name}</p>
                                        <p className="sf-mono cell-preview-message" style={{opacity: '0'}}>0</p>
                                    </div> */}
                                    <div className="product-horizontal-container v-gap-4">
                                    {errorParent.errors.map((error, index) => (
                                            <CellPreview 
                                                rowNumber={errorParent.row_number}
                                                columnName={uploadedInventory?.header_mappings?.[error.field]}
                                                value={errorParent.rowData?.[headerData.indexOf(uploadedInventory?.header_mappings?.[error.field])]}
                                                message={error.message}
                                            />
                                    ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="product-horizontal-container">
                        <button className="keystone-primary-button" onClick={commitInventory}>Commit</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadFlow;
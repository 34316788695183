import './style/ChipRounded.css';
import React, { useState, useRef, useEffect } from 'react';

const ChipRounded = ({ label }) => {
    return (
        <div className="keystone-chip-rounded">
            <p>{label}</p>
        </div>
    );
};

export default ChipRounded;
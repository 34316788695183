import './style/Icon.css';
import React, { useState, useRef, useEffect } from 'react';

const Icon = ({ icon, color = 'black', size = '24', hoverColor = 'black', stylisticFill = false, mask = true }) => {
    const [localColor, setLocalColor] = useState(color);

    const getStyle = () => {
        let style = {
            maskImage: `url(${icon})`,
            WebkitMaskImage: `url(${icon})`,
            backgroundColor: localColor,
            width: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
            minHeight: `${size}px`,
        };

        if (!mask) {
            style = {
                ...style,
                maskImage: 'none',
                WebkitMaskImage: 'none',
                backgroundImage: `url(${icon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: 'transparent',
            };
        }

        // if (stylisticFill) {
        //     style = {
        //         ...style,
        //         width: '500px',
        //         height: '500px',
        //         position: 'absolute',
        //     };
        // }
        return style;
    }
    return (
        <div 
            className="keystone-icon-wrapper"
            style={getStyle()}
            onMouseEnter={() => setLocalColor(hoverColor)}
            onMouseLeave={() => setLocalColor(color)}
        >
        </div>
    );
};

export default Icon;
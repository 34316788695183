import './style/Bento.css';
import React, { useState } from 'react';

const Bento = ({ icon, iconOnly, iconSize = 44, label, value, tooltipData, theme, themeValue }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const calculateGradient = (value) => {
        const shinePosition = 75;
        
        if (value >= 0.5 && value <= 0.66) {
            // 50-75% (Silver - Vibrant)
            return `linear-gradient(45deg, #C0C0C0, #E6E6E6 ${shinePosition}%)`;
        } else if (value > 0.66) {
            // 75-100% (Gold - Vibrant)
            return `linear-gradient(45deg, #FFD700, #FFC125 ${shinePosition}%)`;
        } else {
            return `linear-gradient(45deg, #DDECEC, #DDECEC`;
        }
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const backgroundStyle = theme === 'percent' ? { backgroundImage: calculateGradient(themeValue) } : { backgroundColor: '#DDECEC' };

    return (
        <div 
            className="keystone-bento product-vertical-container" 
            style={backgroundStyle} 
            onMouseOver={() => setShowTooltip(true)} 
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div className="product-horizontal-container align-center">
                <img style={{width: iconSize + 'px', height: iconSize + 'px'}} src={icon} alt="icon" />
                {!iconOnly && <div className="product-vertical-container">
                    <p className="bold-12">{label}</p>
                    <p>{value}</p>
                </div>}
            </div>
            {showTooltip && tooltipData && (
                <div className="product-horizontal-container tooltip">
                    {tooltipData.map((data, index) => (
                        <div key={index} className="tooltip-content">
                            <p className="bold-12">{data.label}</p>
                            <p>{data.value}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Bento;
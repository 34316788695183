import './style/Offers.css';
import React, { useState, useRef, useEffect } from 'react';
import ChipRounded from './ChipRounded';
import Icon from './Icon';
import network from '../../media/network@3x.png';
import TabGroup from './TabGroup';
import Button
 from './Button';
const Offers = ({ props, setProps, setShowModal, setModalType }) => {
    const testOffers = [{
        offer_id: 1,
        offer_total_price: 38169.48,
        offer_total_quantity: 42933,
        offer_percent_of_unit_cost: 0.0688,
        items: [{
            internal_item: "G5654-057",
            internal_part_number: "LTC1487CS8#PBF",
            internal_description: "IC,TRANSCVR,RS422/RS485,250Kbps,SO8",
            internal_manufacturer: "ANALOG DEVICES INC.",
            offer_unit_price: 0.95900,
            offer_quantity: 10608,
            offer_total_price: 10173.07200,
            offer_percent_of_unit_cost: 0.6026
        },
        {
            internal_item: "G5291-007",
            internal_part_number: "MAX1978ETM+",
            internal_description: "IC,Integrated TempCntlPeltier Mod,ROHS",
            internal_manufacturer: "MAXIM / DALLAS DIRECT",
            offer_unit_price: 1.00100,
            offer_quantity: 22541,
            offer_total_price: 22563.54100,
            offer_percent_of_unit_cost: 0.0449
        },
        {
            internal_item: "G5687-001",
            internal_part_number: "LFXP3C-3TN100C",
            internal_description: "FPGA, 62IO,Non-Volatile, 3.3V, 100TQFP",
            internal_manufacturer: "LATTICE SEMICONDUCTOR",
            offer_unit_price: 0.81480,
            offer_quantity: 2697,
            offer_total_price: 2197.51560,
            offer_percent_of_unit_cost: 0.1051
        },
        {
            internal_item: "G5775-3100",
            internal_part_number: "MAX3100EEE+",
            internal_description: "UART, SPI/uWire compatable, RS232, RS485",
            internal_manufacturer: "MAXIM / DALLAS DIRECT",
            offer_unit_price: 0.38500,
            offer_quantity: 5363,
            offer_total_price: 2064.75500,
            offer_percent_of_unit_cost: 0.2099
        },
        {
            internal_item: "G5411-500",
            internal_part_number: "M3062LFGPGP#U3C",
            internal_description: "Microcomputer, 16 Bit, CMOS, P-LQFP100",
            internal_manufacturer: "Renesas Electronics, Inc.",
            offer_unit_price: 0.67900,
            offer_quantity: 1724,
            offer_total_price: 1170.59600,
            offer_percent_of_unit_cost: 0.2142
        }]
    }]

    const handleModalOpen = () => {
        setModalType("inventory-access");
        setShowModal(true);
    }

    const goToSettings = () => {
        setModalType("create-account");
        setShowModal(true);
    };

    const handleViewOffer = (e, offer) => {
        setProps({
            ...props,
            trayOpen: true,
            trayData: offer,
            trayType: "offer"
        })
    };

    return (
        <div className="keystone-offers product-horizontal-container space-between h-gap-16 width-100">
            <div id={props?.page !== "offers" && "strategy-suggestion"} className="product-vertical-container flex-1 v-gap-16">
                {props?.page !== "offers" && (
                    <div className="product-horizontal-container align-center space-between">
                        <ChipRounded label="Offers" />
                        <p className="sf-pro disabled blue-dark">{props?.offersEnabled ? "No Offers Received" : "Not Accepting Offers"}</p>
                    </div>
                )}
                {!props?.offersEnabled && (
                    <div className={`offers-promo product-vertical-container v-gap-16 ${props?.page === "offers" && 'margin-32'}`}>
                        <div className="product-horizontal-container h-gap-4 relative space-between">
                            <div className="product-vertical-container v-gap-4 flex-1">
                                <p className="bold-14">Inventory Access</p>
                                <p className="sf-pro flex-1 ks-description">Your inventory is hidden by default from prospective buyers.</p>

                            </div>
                            <Icon icon={network} size={36} stylisticFill={true} />
                        </div>
                        <div className="product-horizontal-container justify-end h-gap-16">
                            <button className="ks-button link" onClick={handleModalOpen}>Learn More</button>
                            <button className="ks-button white" onClick={goToSettings}>Go to Setting</button>
                        </div>
                    </div>
                )}
                {props?.offersEnabled && props?.page === "offers" && (
                    <div className="product-vertical-container pad-32 v-gap-32">
                        <TabGroup props={props} setProps={setProps} tabs={["Active", "Accepted", "Completed", "Rejected"]}/>
                        {props?.offersTab === 0 && (
                            testOffers.map((offer, index) => (
                                <div key={index} className="offer-card product-horizontal-container space-between align-center keystone-card pad-32">
                                    <div className="product-vertical-container flex-1 v-gap-8">
                                        <p className="bold-14">Offer #{offer.offer_id}</p>
                                        <p className="sf-pro">Total Price: ${offer.total_price} | Total Quantity: {offer.total_quantity} | Total Items: {offer.items?.length}</p>
                                    </div>
                                    <Button
                                        label="View"
                                        brand={true}
                                        onClick={(e) => handleViewOffer(e, offer)}
                                        />
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Offers;
import './style/OfferDetails.css';
import React, { useState, useRef, useEffect } from 'react';
import Button from './Button';
import { formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';
import ScoreBar from './ScoreBar';
import Divider from './Divider';

const OfferDetails = ({ props, setProps, handleSubmit, inventoryData }) => {
    const [appendedData, setAppendedData] = useState([]);
    const [totalDistTimesOfferQuantity, setTotalDistTimesOfferQuantity] = useState(0);
    const [totalMktTimesOfferQuantity, setTotalMktTimesOfferQuantity] = useState(0);
    const [minPriceTimesOfferQuantity, setMinPriceTimesOfferQuantity] = useState(0);

    useEffect(() => {
        if (props?.trayData?.items) {
            const appendedData = props?.trayData?.items.map((item) => {
                const inventory = inventoryData.find((inventory) => inventory.mpn === item.internal_part_number);
                return {
                    ...item,
                    inventory_item: inventory,
                    dist_times_offer_quantity: item.offer_quantity * (inventory?.associated_part?.estimated_distributor_cost || 0),
                    mkt_times_offer_quantity: item.offer_quantity * (inventory?.associated_part?.lowest_market_price || 0),
                };
            });
            setAppendedData(appendedData);

            const totalDistTimesOfferQuantity = appendedData.reduce((acc, item) => acc + item.dist_times_offer_quantity, 0);
            const totalMktTimesOfferQuantity = appendedData.reduce((acc, item) => acc + item.mkt_times_offer_quantity, 0);
            const minPriceTimesOfferQuantity = appendedData.reduce((acc, item) => acc + item.offer_quantity * item.inventory_item?.minimum_price, 0);
            setTotalDistTimesOfferQuantity(totalDistTimesOfferQuantity);
            setTotalMktTimesOfferQuantity(totalMktTimesOfferQuantity);
            setMinPriceTimesOfferQuantity(minPriceTimesOfferQuantity);
        }
    }, [props?.trayData]);

    return (
        <div className="keystone-offer-details product-vertical-container flex-1">
            <div className="product-vertical-container offer-tray-header width-100 space-between pad-24">
                <Button
                    label="Dismiss"
                    onClick={() => setProps({ ...props, trayOpen: false })}
                    onBrand={true} />
                <p className="bold-32">Offer #{props?.trayData?.offer_id}</p>
            </div>
            <div className="product-vertical-container flex-1 width-100">
                <div className="product-vertical-container flex-1 v-gap-12 pad-24">
                    {appendedData.map((item, index) => (
                        <div key={index} className="product-vertical-container keystone-card pad-12 width-100 v-gap-12">
                            <div className="product-horizontal-container width-100 v-gap-12 align-center space-between">
                                <div className="product-vertical-container flex-1">
                                    <p className="sf-mono">{item.internal_part_number}</p>
                                    <p className="sf-mono uppercase bold-12 secondary">{item.internal_manufacturer} {item.inventory_item?.associated_part && "has market data"}</p>
                                </div>
                                <div className="product-vertical-container flex-1 justify-end">
                                    <p className="sf-mono right-align">{formatToCurrency(item.offer_total_price)}</p>
                                    <p className="sf-mono bold-12 right-align secondary">{item.offer_quantity} x {formatToCurrencyExpanded(item.offer_unit_price)}</p>
                                </div>
                            </div>
                            <ScoreBar score={item.offer_percent_of_unit_cost} />
                        </div>
                    ))}
                </div>
                <div className="product-vertical-container">
                    <Divider />
                    <div className="product-horizontal-container space-between pad-24">
                        <p>{minPriceTimesOfferQuantity}</p>
                        <p>{totalDistTimesOfferQuantity}</p>
                        <p>{totalMktTimesOfferQuantity}</p>
                    </div>
                    <div className="product-vertical-container pad-24">
                        <div className="product-horizontal-container flex-1 justify-end space-between pad-12 align-center h-gap-16">
                            <div className="product-vertical-container flex-2 justify-end">
                                <p className="bold-14">Transaction Fee</p>
                                <p className="bold-12 secondary">Incl. negotiation, advertising, and shipping costs</p>
                            </div>
                            <p className="sf-mono size-20 justify-end flex-1">{formatToCurrency(props?.trayData?.offer_total_price * 0.16)}</p>
                        </div>
                        <div className="product-horizontal-container flex-1 justify-end space-between pad-12 align-center h-gap-16">
                            <div className="product-vertical-container flex-2 justify-end">
                                <p className="bold-14">Your Return</p>
                                <p className="bold-12 secondary">After transaction fee.</p>
                            </div>
                            <p className="sf-mono size-24 justify-end flex-1">{formatToCurrency(props?.trayData?.offer_total_price * 0.84)}</p>
                        </div>
                    </div>
                    <div className="product-horizontal-container justify-end pad-32">
                        <Button
                            brand={true}
                            label="Accept Offer"
                            large={true}
                            onClick={() => handleSubmit(props?.trayType)} />
                    </div>
                </div>
                {/* <div className="product-vertical-container offer-total-wrapper pad-12 v-gap-12">
                    <div className="product-horizontal-container flex-1 justify-end space-between">
                        <p className="sf-mono white bold">Total</p>
                        <p className="sf-mono right-align white bold">{formatToCurrency(props?.trayData?.offer_total_price)}</p>
                    </div>
                    <ScoreBar score={props?.trayData?.offer_percent_of_unit_cost} invert={true} />
                </div> */}
            </div>
        </div>
    );
};

export default OfferDetails;
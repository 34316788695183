export const formatToCurrency = (value) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const formatToCurrencyExpanded = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 5, maximumFractionDigits: 5 }).format(value);
};

const materialCostPerPound = {
    'stainless-steel': 0.52,
    'aluminum': 0.44,
    'steel': 0.16,
    'copper': 3.1,
};

export const getCustomValueForRow = (row) => {
    const weight = row['manual-weight'] !== null ? row['manual-weight'] : 0;
    return weight * (row['manual-material'] !== null ? materialCostPerPound[row['manual-material']] : 0);
};

export const getValueForRow = (row, selectedCostColumn, selectedQtyColumn, costType, urgency) => {
    const selectedCostValue = row[selectedCostColumn];
    const isCustom = row['manual-type'] === 'custom';

    let valueIfSold = 0;
    if (isCustom) {
        valueIfSold = getCustomValueForRow(row);
    } else if (selectedCostValue) {
        valueIfSold = Number(selectedCostValue?.toString().replace(/[^0-9.]+/g, ''));

        if (costType === 'unit') {
            valueIfSold *= Number(row[selectedQtyColumn]?.toString().replace(/[^0-9.]+/g, '')) || 1;
        }
    }

    const multiple = urgency === 2 ? 1 : urgency === 1 ? 0.82 : 0.64;
    return valueIfSold * multiple;
};

export const getValueForRowFormatted = (row, selectedCostColumn, selectedQtyColumn, costType, urgency) => {
    return formatToCurrency(getValueForRow(row, selectedCostColumn, selectedQtyColumn, costType, urgency));
};
import './style/Settings.css';
import React, { useState, useRef, useEffect } from 'react';
import TabGroup from './TabGroup';
import Switch from './Switch';
import Button from './Button';
import SettingSection from './SettingSection';

const Settings = ({ props, setProps }) => {
    const [tabs, setTabs] = useState(["Organization", "Notifications", "Account"]);
    const [buttonEnabled, setButtonEnabled] = useState(props?.offersEnabled || false);
    const settings = [{
        name: "offersEnabled",
        enabledTitle: "Receive Offers",
        disabledTitle: "Receive Offers",
        enabledDescription: "Your inventory is able to receive offers by prospective buyers with no commitment to accept.",
        disabledDescription: "Your inventory is hidden to prospective buyers by default. When enabled, offers can be made on items with no commitment to accept."
    }]

    return (
        <div id="keystone-settings">
            <div className="product-vertical-container pad-32 v-gap-32">
                <TabGroup
                    props={props}
                    setProps={setProps}
                    tabs={tabs}
                    setTabs={setTabs}
                />
                {props?.[props?.page + "Tab"] === 0 && (
                    <SettingSection
                        props={props}
                        setProps={setProps}
                        name="Offers"
                        settings={settings}
                        />
                )}
            </div>
        </div>
    );
};

export default Settings;
import './style/VerticalVisual.css';
import React from 'react';

const VerticalVisual = ({ ratio, icon }) => {
  // Ensure ratio is between 0 and 1
  const clampedRatio = Math.max(0, Math.min(1, ratio));
  const isOver = clampedRatio >= 0.5;
  const indicatorHeight = clampedRatio * 100;

  return (
    <div className="keystone-vertical-visual">
      <div className="vertical-visual-background">
        <div
          className={`vertical-visual-indicator ${isOver ? 'over' : 'under'}`}
          style={{ height: `${indicatorHeight}%` }}>
        </div>
        <div className="vertical-visual-center-line"></div>
      </div>
    </div>
  );
};

export default VerticalVisual;
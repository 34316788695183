import './style/Tray.css';
import React, { useEffect, useState } from 'react';
import OfferDetails from './OfferDetails';

const Tray = ({ props, setProps, className, productData, handleSubmit, inventoryData }) => {

    return (
        <div id="keystone-tray" className={`product-vertical-container align-start v-gap-8 ${className}`}>
            {props?.trayType === "offer" && (
                <OfferDetails
                    props={props}
                    setProps={setProps}
                    handleSubmit={handleSubmit}
                    inventoryData={inventoryData} />
            )}
        </div>
    );
};

export default Tray;
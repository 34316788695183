import './style/Loader.css';
import React, { useState, useRef, useEffect } from 'react';

const Loader = ({ loading }) => {
    return (
        <div id="keystone-loader" style={loading ? {opacity: '1'} : {opacity: '0'}}>
            <div className="keystone-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;
import './style/HeaderVisual.css';
import React, { useState, useRef, useEffect } from 'react';
import info from '../../media/info32@3x.png';
import upArrow from '../../media/up-arrow@3x.png';
import downArrowThick from '../../media/down-arrow-thick@3x.png';
import downArrow from '../../media/down-arrow@3x.png';

const HeaderVisual = ({ label, tooltip, fancy, value, status, headerData }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [selectedHeaderIndex, setSelectedHeaderIndex] = useState(null);
    const [selectedHeaderMatchesAutoMapping, setSelectedHeaderMatchesAutoMapping] = useState(true);

    const handleHover = () => {
        setShowTooltip(true);
    };
    const handleLeave = () => {
        setShowTooltip(false);
    };

    useEffect(() => {
        if (headerData?.indexOf(value) !== -1) {
            setSelectedHeaderIndex(headerData?.indexOf(value))
            setSelectedHeaderMatchesAutoMapping(true);
        }
    }, [headerData]);

    const handleSelectChange = (e) => {
        setSelectedHeaderIndex(Number(e.target.value));
        setSelectedHeaderMatchesAutoMapping(Number(e.target.value) === headerData?.indexOf(value) ? true : false);
    }

    return (
        <div className={!selectedHeaderMatchesAutoMapping ? "updated" + " " + "product-vertical-container fit-content header-visual" : status ? status + " " + "product-vertical-container fit-content header-visual" : "product-vertical-container fit-content header-visual"}>
            {fancy && <div className="header-visual-vertical"></div>}
            {status && headerData && (
                <div className="header-visual-select-wrapper">
                    <select className="header-visual-select" value={selectedHeaderIndex} onChange={handleSelectChange}>
                        <option value={null}>Select Header</option>
                        {Object.keys(headerData).map((key, index) => (
                            <option key={index} value={key}>{headerData[key] && headerData[key] !== "" ? headerData[key] : "Blank"}</option>
                        ))}
                    </select>
                </div>
            )}
            {status && headerData && <div className="product-vertical-container align-center header-visual-arrow">
                <div className="vertical-divider"></div>
                <div className="image-wrapper">
                    <img className="img-12" src={downArrowThick}></img>
                </div>
                <div className="vertical-divider"></div>
            </div>}
            <div className="product-horizontal-container">
                {fancy && <div className="header-visual-horizontal"></div>}
                <div className="keystone-header-visual">
                    <div className="product-vertical-container v-gap-4 flex-1">
                        <div className="product-horizontal-container space-between h-gap-4 flex-1">
                            <p className={status === "negative" ? "keystone-header-visual-label negative" : status === "positive" ? "keystone-header-visual-label positive" : "keystone-header-visual-label"}>{label}</p>
                            <img src={info} alt="info" onMouseEnter={handleHover} onMouseLeave={handleLeave}/>
                        </div>
                        {/* {status && <p className="keystone-header-visual-label light ">{status === "negative" ? "Not Found" : value}</p>} */}
                    </div>
                    {showTooltip && <div className="header-visual-tooltip"><p className="sf-pro">{tooltip}</p></div>}
                </div>
                {fancy && <div className="header-visual-horizontal"></div>}
            </div>
            {fancy && <div className="header-visual-vertical"></div>}
        </div>
    );
};

export default HeaderVisual;
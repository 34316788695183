import './style/InventoryRow.css';
import React, { useState, useRef, useEffect } from 'react';
import ScoreBar from './ScoreBar';
import { formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';
import Icon from './Icon';

const InventoryRow = ({ item, setSelectedInventoryId, userPreferences }) => {
    return (
        <div className="keystone-inventory-row product-horizontal-container h-gap-16 space-between align-center" onClick={() => setSelectedInventoryId(item.id)}>
            {userPreferences?.hiddenColumns?.includes("MPN") ? <Icon size="16" /> : <p className="keystone-row-data text-mono">{userPreferences?.hiddenColumns?.includes("MPN") ? " " : item.mpn}</p>}
            {userPreferences?.hiddenColumns?.includes("Minimum Price") ? <Icon size="16" /> : <p className="keystone-row-data number">{formatToCurrencyExpanded(item.minimum_price)}</p>}
            {userPreferences?.hiddenColumns?.includes("Total Min. Price") ? <Icon size="16" /> : <p className="keystone-row-data number">{formatToCurrency(item.minimum_price * item.quantity)}</p>}
            {userPreferences?.hiddenColumns?.includes("Market Price") ? <Icon size="16" /> : <p className="keystone-row-data number">{item.associated_part?.lowest_market_price && item.associated_part?.lowest_market_price !== 0 ? formatToCurrencyExpanded(item.associated_part?.lowest_market_price) : ""}</p>}
            {userPreferences?.hiddenColumns?.includes("Total Mkt. Price") ? <Icon size="16" /> : <p className="keystone-row-data number">{item.associated_part?.lowest_market_price && item.associated_part?.lowest_market_price !== 0 ? formatToCurrency(item.associated_part?.lowest_market_price * item.quantity) : ""}</p>}
            {userPreferences?.hiddenColumns?.includes("Market Manufacturer") ? <Icon size="16" /> : <p className="keystone-row-data">{item.associated_part?.manufacturer}</p>}
            {userPreferences?.hiddenColumns?.includes("Quantity") ? <Icon size="16" /> : <p className="keystone-row-data number">{item.quantity}</p>}
            {/* <p>{item.associated_part?.internal_weight_total?.toFixed(2) || ""}</p>
            <p>{item.computed?.price_ratio?.toFixed(2) || ""}</p>
            <p>{item.computed?.stock_ratio?.toFixed(2) || ""}</p>
            <p>{item.associated_part?.internal_weight_total?.toFixed(2) || ""}</p> */}
            {userPreferences?.hiddenColumns?.includes("Score") ? <Icon size="16" /> : <ScoreBar score={item.computed?.score_clamped} gold={item.associated_part?.unique_aspects_percent > 0.5} />}
        </div>
    );
};

export default InventoryRow;
import './style/Tab.css';
import React, { useState, useRef, useEffect } from 'react';

const Tab = ({ index, props, setProps, label, active }) => {
    return (
        <div className="keystone-tab">
            <button className={`ks-button tab ${active && 'active'}`} onClick={() => {
                setProps({ ...props, [props?.page + "Tab"]: index });
            }}>{label}</button>
        </div>
    );
};

export default Tab;
import './style/Keystone.css';
import React, { useEffect, useState, useRef } from 'react';
import Menu from '../component/keystone/Menu';
import Tray from '../component/keystone/Tray';
import Content from '../component/keystone/Content';
import KeystoneData from '../media/keystone-data-2.tsv';
import CompanyData from '../media/company-data.json';
import Modal from '../component/keystone/Modal';
import Loader from '../component/keystone/Loader';

const Keystone = () => {
    const [props, setProps] = useState({
        page: "inventory",
        offersEnabled: false,
        trayOpen: false
    });
    const [isKeystonePage, setIsKeystonePage] = useState(true);
    const [menuOpen, setMenuOpen] = useState(true);
    const [productData, setProductData] = useState(null);
    const [inventoryData, setInventoryData] = useState(null);
    const [tsvInventoryData, setTsvInventoryData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const fileInputRef = useRef(null);
    const [accessToken, setAccessToken] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [fileUploading, setFileUploading] = useState(false);
    const [uploadedInventory, setUploadedInventory] = useState(null);
    const [operationId, setOperationId] = useState(null);
    const [demoCompleted, setDemoCompleted] = useState(false);
    const [selectedInventoryId, setSelectedInventoryId] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [totalMinimumPrice, setTotalMinimumPrice] = useState(0);
    const [modalType, setModalType] = useState("upload-flow");
    const [userPreferences, setUserPreferences] = useState({
        sortBy: 'Score',
    });

    useEffect(() => {
        setSelectedInventoryId(null);
    }, [props?.page]);

    useEffect(() => {
        setSelectedInventoryId(null);
    }, [props?.page]);

    useEffect(() => {
        fetch(KeystoneData)
            .then(response => response.text())
            .then(data => {
                const tsvInventoryData = tsvToJson(data);
                setTsvInventoryData(tsvInventoryData);
            })
            .catch(error => {
                console.log('Error fetching tsv data:', error);
            });
    }, []);

    const tsvToJson = (tsv) => {
        const lines = tsv.trim().split('\n');
        const headers = lines[0].split('\t');
      
        return lines.slice(1).map(line => {
          const values = line.split('\t');
          let item = {};
          headers.forEach((header, index) => {
            item[header] = values[index];
          });
          return item;
        });
    };

    useEffect(() => {
        if (tsvInventoryData) {
            // Use the query param mc to get the magic link code
            verifyMagicLink(new URLSearchParams(window.location.search).get('mc') || 'FOREVAN');
        }
    }, [tsvInventoryData]);

    useEffect(() => {
        if (file) {
            uploadInventory(file);
        }
    }, [file]); 


    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            console.log(event.target.files[0]);
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);
            setFileUploading(true);
        }
    };

    const removeFile = () => {
        setFile(null);
        setFileName(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const verifyMagicLink = async (magicLink) => {
        setShowLoader(true);
        try {
            const response = await fetch('http://localhost:8080/keystone/magic_link/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: magicLink }),
            });
            const data = await response.json();
            console.log(data);
            if (data && data.access_token) {
                setAccessToken(data.access_token);
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            console.log('Error verifying magic link:', error);
            setShowLoader(false);
        }
    }

    const getInventory = async (validToken) => {
        if (props?.page === "inventory") {
            try {
                const response = await fetch('http://localhost:8080/keystone/inventory', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${validToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                if (data?.inventory_data?.length > 0) {
                    const newData = await processInventory(data);
                    setInventoryData(newData);
                    setDemoCompleted(true);
                }

                setShowLoader(false);
            } catch (error) {
                console.log('Error fetching inventory:', error);
                setShowLoader(false);
            }
        }
    }

    useEffect(() => {
        let intervalId;
      
        if (accessToken && props?.page === "inventory") {
          getInventory(accessToken); // Fetch immediately on mount
      
          // Set the interval to fetch every 5 seconds
          intervalId = setInterval(() => {
            getInventory(accessToken);
          }, 5000);
        }
      
        // Clean up function that clears the interval
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
    }, [accessToken, props?.page]); 

    const uploadInventory = async (file) => {
        setShowLoader(true);
        const formData = new FormData();
        formData.append('file', file);
        const response = await fetch('http://localhost:8080/keystone/inventory/upload', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
        });
        const data = await response.json();
        console.log(data);

        if (data && data.validation_errors && data.validation_errors.length > 0) {
            setValidationErrors(data.validation_errors);
        }

        if (data) {
            setUploadedInventory(data);
        }

        if (data && data.operation_id) {
            setOperationId(data.operation_id);
        }
        
        setShowLoader(false);
        setFileUploading(false);
    }

    const commitInventory = async () => {
        setShowLoader(true);
        if (operationId) {
            const response = await fetch('http://localhost:8080/keystone/inventory/commit', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "operation_id": operationId,
                }),
            });
            const data = await response.json();

            if (data?.inventory_data?.length > 0) {
                const newData = await processInventory(data);
                setInventoryData(newData);
                setDemoCompleted(true);
            }

            setShowLoader(false);
        }
    }

    const getUniqueAspects = (specs) => {
        let uniqueAspectsCount = 0;
        let uniqueAspects = {};

        if (specs.some(spec => spec.name === "RoHS" && spec.value === "Compliant")) {
            uniqueAspects.rohs_compliant = true;
            uniqueAspectsCount++;
        }

        if (specs.some(spec => spec.name === "Lead Free" && spec.value === "Lead Free")) {
            uniqueAspects.lead_free = true;
            uniqueAspectsCount++;
        } else if (specs.some(spec => spec.name === "Lead Free" && spec.value === "Contains Lead")) {
            uniqueAspects.lead_free = false;
        }

        if (specs.some(spec => spec.name === "REACH SVHC" && spec.value === "Yes")) {
            uniqueAspects.reach_svhc = true;
            uniqueAspectsCount++;
        } else if (specs.some(spec => spec.name === "REACH SVHC" && spec.value === "No SVHC")) {
            uniqueAspects.reach_svhc = false;
        }

        const minOperatingTemp = specs.filter(spec => spec.name.toLowerCase().includes('min operating temperature'))?.[0]?.value;
        const maxOperatingTemp = specs.filter(spec => spec.name.toLowerCase().includes('max operating temperature'))?.[0]?.value;

        if (minOperatingTemp && maxOperatingTemp) {
            if (parseFloat(minOperatingTemp) <= -55 && parseFloat(maxOperatingTemp) >= 125) {
                uniqueAspects.wide_temp_range = true;
                uniqueAspectsCount++;
            } else {
                uniqueAspects.wide_temp_range = false;
            }
        }

        if (specs.some(spec => spec.name === "Radiation Hardening" && spec.value === "Yes")) {
            uniqueAspects.radiation_hardened = true;
            uniqueAspectsCount++;
        } else if (specs.some(spec => spec.name === "Radiation Hardening" && spec.value === "No")) {
            uniqueAspects.radiation_hardened = false;
        }


        if (specs.some(spec => spec.name === "Military Standard" && spec.value === "Not")) {
            uniqueAspects.military_standard = false;
        } else if (specs.some(spec => spec.name === "Military Standard" && spec.value !== "Not" && spec.value !== "")) {
            uniqueAspects.military_standard = true;
            uniqueAspects.military_standard_value = specs.filter(spec => spec.name === "Military Standard")?.[0]?.value;
            uniqueAspectsCount++;
        }

        const weightSpec = specs.find(spec => spec.name.toLowerCase().includes('weight'));
        if (weightSpec) {
            uniqueAspects.weight = parseFloat(weightSpec.value);
            uniqueAspects.weight_value_type = weightSpec.value_type;
            uniqueAspects.weight_unit = weightSpec.units;

            if (weightSpec.units === "µg") {
                uniqueAspects.weight = uniqueAspects.weight / 1000000000; // Convert µg to kg
                uniqueAspects.weight_unit = "kg";
            } else if (weightSpec.units === "mg") {
                uniqueAspects.weight = uniqueAspects.weight / 1000000; // Convert mg to kg
                uniqueAspects.weight_unit = "kg";
            } else if (weightSpec.units === "g") {
                uniqueAspects.weight = uniqueAspects.weight / 1000; // Convert g to kg
                uniqueAspects.weight_unit = "kg";
            }
        }

        const lifecycleSpec = specs.find(spec => spec.name.toLowerCase().includes('lifecyle'));
        if (lifecycleSpec?.value?.toLowerCase() === "production" || lifecycleSpec?.value?.toLowerCase() === "active") {
            uniqueAspects.lifecycle_status = "ACTIVE";
        } else if (lifecycleSpec?.value?.toLowerCase() === "obsolete") {
            uniqueAspects.lifecycle_status = "INACTIVE";
            uniqueAspectsCount++;
        } else if (lifecycleSpec?.value?.toLowerCase() === "eol" || lifecycleSpec?.value?.toLowerCase() === "to be discontinued") {
            uniqueAspects.lifecycle_status = "EOL";
            uniqueAspectsCount++;
        } else if (lifecycleSpec?.value?.toLowerCase() === "nrnd") {
            uniqueAspects.lifecycle_status = "NRND";
            uniqueAspectsCount++;
        }

        uniqueAspects.unique_aspects_count = uniqueAspectsCount;
        uniqueAspects.unique_aspects_percent = uniqueAspectsCount / 7;

        return uniqueAspects;
    }

    const handleTraySubmit = (trayType) => {
        if (trayType === "offer") {

        }
    }
    
    const processInventory = async (data) => {
        const stockWeight = 0.1;
        const uniqueWeight = 0.1;
        const valueWeight = 0.8;

        const totalMinPrice = data.inventory_data.reduce((acc, item) => {
            return item.minimum_price && item.quantity ? acc + (item.minimum_price * item.quantity) : acc;
        }, 0);

        setTotalMinimumPrice(totalMinPrice);

        let processedData = data.inventory_data.map((item) => {
            const normalizedItem = item.normalized_item;

            if (!normalizedItem) {
                console.log("No normalized data found for pn: ", item.mpn);
                return item;
            }

            const partData = normalizedItem.metadata?.search_data;

            let newData = {};
            newData.avg_total_avail = partData.sellers?.length * partData.avg_avail;

            newData.specs = partData.specs.map(spec => {
                return {
                    name: spec.attribute.name,
                    type: spec.attribute.group,
                    value: spec.value,
                    value_type: spec.value_type,
                    units: spec.units
                }
            });

            let specsByGroup = {};
            newData.specs.forEach(spec => {
                if (!specsByGroup[spec.type]) {
                    specsByGroup[spec.type] = [];
                }
                specsByGroup[spec.type].push(spec);
            });
            newData.specsByGroup = specsByGroup;

            const lowestPrices = partData.sellers?.filter(seller => seller.offers?.filter(offer => offer.prices?.length > 0).length > 0)[0]?.offers?.[0]?.prices;
            newData.lowest_market_price = lowestPrices && lowestPrices.length > 0 ? lowestPrices[lowestPrices.length - 1]?.price : partData.median_price_1000?.price ? partData.median_price_1000?.price : 0;
            newData.estimated_distributor_cost = newData.lowest_market_price ? newData.lowest_market_price * 0.4 : 0;
            newData.sellers_with_priced_offers = partData.sellers?.filter(seller => seller.offers?.filter(offer => offer.prices?.length > 0).length > 0).length;
            
            newData.total_avail = partData.sellers?.filter(seller => seller.offers?.length > 0)?.reduce((acc, seller) => {
                const sellerTotal = seller.offers?.reduce((acc, offer) => {
                    return acc + offer.inventory_level;
                }, 0);
                return acc + sellerTotal;
            }, 0);

            newData.total_avail_instant = partData.sellers?.filter(seller => seller.offers?.filter(offer => offer.prices?.length > 0).length > 0)?.reduce((acc, seller) => {
                const sellerTotal = seller.offers?.reduce((acc, offer) => {
                    return acc + offer.inventory_level;
                }, 0);
                return acc + sellerTotal;
            }, 0);

            newData.total_avail_rfq = newData.total_avail - newData.total_avail_instant;

            const uniqueAspects = newData?.specs?.length > 0 ? getUniqueAspects(newData.specs) : [];
            if (uniqueAspects.weight) {
                newData.internal_weight_total = uniqueAspects.weight * item.quantity; // TODO: RENAME
            }

            if (partData.estimated_factory_lead_days) {
                newData.estimated_factory_lead_days = partData.estimated_factory_lead_days;
                newData.estimated_factory_lead_weeks = Math.ceil(partData.estimated_factory_lead_days / 7);
            }

            if (partData.short_description) {
                newData.short_description = partData.short_description;
            }

            if (partData.category?.name) {
                newData.category = partData.category.name;
            }

            if (partData.series?.name) {
                newData.series = partData.series.name;
            }

            if (!newData.total_avail && !newData.sellers_with_priced_offers) {
                newData.stock_level = "NONE";
            }

            if (newData.total_avail && newData.avg_total_avail && newData.sellers_with_priced_offers) {
                if ((newData.total_avail / newData.avg_total_avail) > 1.15) {
                    newData.stock_level = "OVER";
                } else if ((newData.total_avail / newData.avg_total_avail) < 0.85) {
                    newData.stock_level = "UNDER";
                } else {
                    newData.stock_level = "AVERAGE";
                }
            }

            if (partData.manufacturer) {
                newData.manufacturer = partData.manufacturer.name;
                newData.manufacturer_url = partData.manufacturer.homepage_url;
            }

            let computed = {};
            computed.spread_delta = newData.lowest_market_price - newData.estimated_distributor_cost;
            computed.spread_delta_one_third = computed.spread_delta / 3;
            computed.spread_delta_two_third = (computed.spread_delta / 3) * 2;
            computed.spread_one_third = computed.spread_delta_one_third + newData.estimated_distributor_cost;
            computed.spread_two_third = computed.spread_delta_two_third + newData.estimated_distributor_cost;
            computed.estimated_distributor_cost_half = newData.estimated_distributor_cost * 0.5;
            computed.total_minimum_price = item.minimum_price * item.quantity;
            computed.percentage_of_total_min_price = computed.total_minimum_price / totalMinPrice;


            const averageQuantity = Math.max(0, newData.avg_total_avail);
            const actualQuantity = Math.max(0, newData.total_avail);
            let stockRatio = averageQuantity / actualQuantity;

            if (averageQuantity === 0 && actualQuantity === 0) {
                stockRatio = 1;
            } else if (actualQuantity === 0) {
                stockRatio = 1;
            } else {
                stockRatio = 1 - (1 / (1 + stockRatio));
            }

            computed.stock_ratio = stockRatio * stockWeight;

            let competitivenessScore = 0;

            if (item.minimum_price <= 0 || newData.estimated_distributor_cost <= 0) {
                competitivenessScore = 0.5;
            } else {
                // Calculate the ratio of estimated distributor cost to product unit cost
                const ratio = newData.estimated_distributor_cost / item.minimum_price;

                // Use a non-linear function to compute the competitiveness score
                competitivenessScore = 1 - (1 / (1 + ratio));

                // Ensure the competitiveness score is between 0 and 1
                competitivenessScore = Math.max(0, Math.min(1, competitivenessScore));
            }

            computed.price_ratio = competitivenessScore;

            // computed.score = computed.stock_ratio + computed.price_ratio;
            computed.score = computed.stock_ratio + (uniqueAspects.unique_aspects_percent * uniqueWeight || 0);

            const output = {
                id: item.id,
                unique_id: item.unique_id,
                mpn: item.mpn,
                manufacturer: item.manufacturer,
                description: item.description,
                condition: item.condition,
                minimum_price: item.minimum_price,
                quantity: item.quantity,
                quantity_type: item.quantity_type,
                warehouse_country: item.warehouse_country,
                warehouse_region: item.warehouse_region,
                associated_part: {
                    ...newData,
                    ...uniqueAspects
                },
                computed
            }
            return output;
        });

        const highestPercentOfTotalMinPrice = processedData.reduce((acc, item) => {
            return item.computed?.percentage_of_total_min_price > acc ? item.computed?.percentage_of_total_min_price : acc;
        }, 0);

        const lowestPercentOfTotalMinPrice = processedData.reduce((acc, item) => {
            return item.computed?.percentage_of_total_min_price < acc ? item.computed?.percentage_of_total_min_price : acc;
        }, 0);

        // add value ratio to each item using the highest and lowest values and clamp between 0 and 1
        processedData.forEach(item => {
            if (item.computed) {
                item.computed.value_ratio = (item.computed?.percentage_of_total_min_price - lowestPercentOfTotalMinPrice) / (highestPercentOfTotalMinPrice - lowestPercentOfTotalMinPrice);
                item.computed.score = item.computed?.score + (item.computed?.value_ratio * valueWeight);
            }
            return item;
        });

        const highestScore = processedData.reduce((acc, item) => {
            return item.computed?.score > acc ? item.computed?.score : acc;
        }, 0);

        const lowestScore = processedData.reduce((acc, item) => {
            return item.computed?.score < acc ? item.computed?.score : acc;
        }, 0);

        // add a score_clamped to computed object for each item using the highest and lowest values and clamp between 0 and 1
        processedData.forEach(item => {
            if (item.computed) {
                item.computed.score_clamped = (item.computed?.score - lowestScore) / (highestScore - lowestScore);
            }
            return item;
        });

        const highestScoreClamped = processedData.reduce((acc, item) => {
            return item.computed?.score_clamped > acc ? item.computed?.score_clamped : acc;
        }, 0);

        const lowestScoreClamped = processedData.reduce((acc, item) => {
            return item.computed?.score_clamped < acc ? item.computed?.score_clamped : acc;
        }, 0);

        return processedData;
    }

    return (
        <div id="keystone">
            <div id="content-wrapper" class={menuOpen && 'menu-open'}>
                <Menu
                    props={props}
                    setProps={setProps}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    className={menuOpen ? 'open' : 'closed'}
                ></Menu>
                <Content
                    props={props}
                    setProps={setProps}
                    productData={productData}
                    setProductData={setProductData}
                    userPreferences={userPreferences}
                    setUserPreferences={setUserPreferences}
                    inventoryData={inventoryData}
                    setShowModal={setShowModal}
                    handleFileUpload={handleFileUpload}
                    file={file}
                    fileName={fileName}
                    removeFile={removeFile}
                    fileInputRef={fileInputRef}
                    demoCompleted={demoCompleted}
                    selectedInventoryId={selectedInventoryId}
                    setSelectedInventoryId={setSelectedInventoryId}
                    modalType={modalType}
                    setModalType={setModalType}
                ></Content>
                <Menu
                    props={props}
                    setProps={setProps}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    className={menuOpen ? 'open' : 'closed'}
                ></Menu>
            </div>
            <Tray
                props={props}
                setProps={setProps}
                className={props?.trayOpen ? 'open' : 'closed'}
                inventoryData={inventoryData}
                productData={productData}
                handleSubmit={handleTraySubmit}
            ></Tray>
            <Modal
                props={props}
                setProps={setProps}
                showModal={!demoCompleted ? true : showModal}
                modalType={modalType}
                setModalType={setModalType}
                setShowModal={setShowModal}
                handleFileUpload={handleFileUpload}
                file={file}
                fileName={fileName}
                removeFile={removeFile}
                fileInputRef={fileInputRef}
                accessToken={accessToken}
                validationErrors={validationErrors}
                fileUploading={fileUploading}
                uploadedInventory={uploadedInventory}
                commitInventory={commitInventory} />
            <Loader loading={showLoader} />
        </div>
    );
};

export default Keystone;
import './style/TermsAgreement.css';
import React, { useState, useRef, useEffect } from 'react';

const TermsAgreement = ({ props, setProps, setShowModal }) => {

    const handleAgreeToTerms = () => {
        setProps({
            ...props,
            page: "settings",
            settingsTab: 0
        });
        setShowModal(false);
    };

    return (
        <div id="keystone-terms-agreement" className="modal-wrapper product-vertical-container">
            <div className="modal-header product-horizontal-container space-between align-center">  
                <button id="close-modal" style={{opacity: '0'}}>Close</button>
                <p className="bold">Terms of Use</p>
                <button className="ks-button bold-14" onClick={() => setShowModal(false)}>Close</button>
            </div>
            <div className="product-vertical-container pad-32 v-gap-24">
                <iframe
                    src="https://www.trymaterialize.com/terms-of-use"
                    title="Terms of Use" />
                <div className="product-horizontal-container space-between align-center">
                    <p className="sf-pro flex-1 center-text">By continuing, you agree to the terms set forth in the document above.</p>
                    <button className="ks-button brand large" onClick={handleAgreeToTerms}>Agree</button>
                </div>
            </div>
        </div>
    );
};

export default TermsAgreement;
import './style/Button.css';
import React, { useState } from 'react';
import LoaderBlock from './LoaderBlock';

const Button = ({ label, large, loading, enabled, onClick, brand, onBrand }) => {

    return (
        <button className={`ks-button ${large && 'large'} ${loading && "loading"} ${enabled && "enabled"} ${brand && "brand"} ${onBrand && "on-brand"} flex align-center h-gap-16`} onClick={onClick}>
            <p>{label}</p>
            {loading && <LoaderBlock />}
        </button>
    );
};

export default Button;
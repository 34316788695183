import './style/CreateAccount.css';
import React, { useState, useRef, useEffect } from 'react';
import google from '../../media/google.png';
import ShieldDisplay from '../../media/shield-display-styled@3x.png';
import PowDisplay from '../../media/pow-display-styled@3x.png';
import Icon from './Icon';
import CheckmarkDotted from '../../media/checkmark-dotted-display-styled@3x.png';
import Divider from './Divider';

const CreateAccount = ({ props, setProps, setShowModal, setModalType }) => {
    const createNewAccount = () => {
        setModalType("tos");
    }

    return (
        <div id="keystone-create-account">
            <div id="create-account-wrapper" className="product-horizontal-container flex-1">
                <div id="create-account-details-pane" className="product-vertical-container flex-1 justify-center v-gap-48">
                    <div className="product-horizontal-container align-center space-between h-gap-12">
                        <Icon icon={ShieldDisplay} size={64} mask={false} />
                        <div className="product-vertical-container">
                            <p className="bold-16">Privacy Guarantee</p>
                            <p className="sf-pro">Corporately identifiable information is never shared.</p>
                        </div>
                    </div>
                    <div className="product-horizontal-container align-center space-between h-gap-12">
                        <Icon icon={CheckmarkDotted} size={64} mask={false} />
                        <div className="product-vertical-container">
                            <p className="bold-16">No Commitment</p>
                            <p className="sf-pro">You are not required to accept or enable offers on your inventory.</p>
                        </div>
                    </div>
                    <div className="product-horizontal-container align-center space-between h-gap-12">
                        <Icon icon={PowDisplay} size={64} mask={false} />
                        <div className="product-vertical-container">
                            <p className="bold-16">Automatic Negotiation</p>
                            <p className="sf-pro">We leverage market insights to reclaim you the most capital.</p>
                        </div>
                    </div>
                </div>
                <div id="create-account-content-wrapper" className="product-vertical-container flex-1">
                    <div id="create-account-header-wrapper" className="product-vertical-container flex-1">
                        <p id="create-account-title">Create Account</p>
                        <p id="create-account-description">Access free market insights and fee-based reclamation tools.</p>
                    </div>
                    <div id="create-account-details-wrapper" className="product-vertical-container flex-1 align-center v-gap-32">
                        <div className="product-vertical-container flex-1 width-100 v-gap-12">
                            <input id="create-account-email-input" type="email" placeholder="Email Address" />
                            <input id="create-account-password-input" type="password" placeholder="Password" />
                            <button className="create-account-button" onClick={createNewAccount}>
                                <span>Create Account</span>
                            </button>
                        </div>
                        <Divider label="or" />
                        <div className="product-vertical-container">
                            <button className="create-account-button with-logo" onClick={createNewAccount}>
                                <img src={google} alt="Google's 'G' Logo"/>
                                <span>Sign up with Google</span>
                                <img src={google} alt="Google's 'G' Logo"/>
                            </button>
                            <p id="create-account-details">Note: Organization email address must be used.</p>
                        </div>
                        <a href='/privacy' id="create-account-privacy-anchor">Privacy Policy</a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CreateAccount;
import './style/InventoryList.css';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ScoreBar from './ScoreBar';
import InventoryRow from './InventoryRow';
import InventoryHeaderColumn from './InventoryHeaderColumn';

const InventoryList = ({ inventoryData, userPreferences, setUserPreferences, setSelectedInventoryId }) => {
    const columns = useMemo(() => [
        "MPN",
        "Minimum Price",
        "Total Min. Price",
        "Market Price",
        "Total Mkt. Price",
        "Market Manufacturer",
        "Quantity",
        "Score"
    ], []);

    const [sortedInventoryData, setSortedInventoryData] = useState(null);

    useEffect(() => {
        if (userPreferences?.sortBy && columns.includes(userPreferences?.sortBy.replace("-ascending", ""))) {
            let sortedInventory = [...inventoryData].sort((a, b) => {
                const aValue = userPreferences?.sortBy.includes('Score') ? a.computed?.score_clamped :
                    userPreferences?.sortBy?.includes('MPN') ? a.mpn.toLowerCase() :
                    userPreferences?.sortBy?.includes('Quantity') ? a.quantity :
                    userPreferences?.sortBy?.includes('Total Min. Price') ? a.minimum_price * a.quantity :
                    userPreferences?.sortBy?.includes('Minimum Price') ? a.minimum_price : 
                    userPreferences?.sortBy?.includes('Market Price') ? a.associated_part?.lowest_market_price :
                    userPreferences?.sortBy?.includes('Market Manufacturer') ? a.associated_part?.manufacturer.toLowerCase() :
                    userPreferences?.sortBy?.includes('Total Mkt. Price') ? a.associated_part?.lowest_market_price * a.quantity :
                    undefined;
                const bValue = userPreferences?.sortBy.includes('Score') ? b.computed?.score_clamped :
                    userPreferences?.sortBy?.includes('MPN') ? b.mpn.toLowerCase() :
                    userPreferences?.sortBy?.includes('Quantity') ? b.quantity :
                    userPreferences?.sortBy?.includes('Total Min. Price') ? b.minimum_price * b.quantity :
                    userPreferences?.sortBy?.includes('Minimum Price') ? b.minimum_price : 
                    userPreferences?.sortBy?.includes('Market Price') ? b.associated_part?.lowest_market_price :
                    userPreferences?.sortBy?.includes('Market Manufacturer') ? b.associated_part?.manufacturer.toLowerCase() :
                    userPreferences?.sortBy?.includes('Total Mkt. Price') ? b.associated_part?.lowest_market_price * b.quantity :
                    undefined;
    
                if ((aValue === undefined || (typeof aValue !== 'string' && isNaN(aValue))) && (bValue === undefined || (typeof bValue === 'string' && isNaN(bValue)))) {
                    return 0; 
                }
                if (aValue === undefined || (typeof aValue !== 'string' && isNaN(aValue))) {
                    return 1; 
                }
                if (bValue === undefined || (typeof aValue !== 'string' && isNaN(bValue))) {
                    return -1; 
                }
    
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                }
    
                return bValue - aValue;
            });
    
            if (userPreferences?.sortBy?.includes("-ascending")) {
                sortedInventory.reverse();
            }
    
            setSortedInventoryData(sortedInventory);
        }
    }, [columns, inventoryData, userPreferences]);

    return (
        <div className="product-vertical-container overflow-auto">
            <div id="keystone-inventory-table-wrapper">
                <div id="keystone-inventory-table" className="pad-24">
                    <div id="keystone-inventory-header" className="product-horizontal-container h-gap-16 space-between">
                        {columns.map((column, index) => (
                            <InventoryHeaderColumn key={index} label={column} userPreferences={userPreferences} setUserPreferences={setUserPreferences} />
                        ))}
                    </div>
                    <div className="product-vertical-container v-gap-8">
                        {sortedInventoryData && sortedInventoryData.map((item, index) => (
                            <InventoryRow item={item} setSelectedInventoryId={setSelectedInventoryId} userPreferences={userPreferences} />
                        ))}
                    </div>
                    <div>
                        <p>{inventoryData.length} Items</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryList;
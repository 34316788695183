import './style/Menu.css';
import React, { useEffect, useState } from 'react';
import CaretRight32px from '../../media/caret-right32px@3x.png';
import CaretLeft32px from '../../media/caret-left32px@3x.png';
import Home32px from '../../media/home-32px@3x.png';
import Speaker32px from '../../media/speaker32px@3x.png';
import Truck32px from '../../media/truck32px@3x.png';
import Bill32px from '../../media/bill32px@3x.png';
import Team32px from '../../media/team32px@3x.png';
import Gear32px from '../../media/gear32px@3x.png';
import AppIcon from '../../media/app-icon.png';

const Menu = ({ props, setProps, menuOpen, setMenuOpen, className, mobile }) => {
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    /* toast and tooltips and modal */
    /* full page loaders and indicators */
    /* skeleton loaders */

    const setPage = (page) => {
        setProps({ ...props, page });
    };
    
    return !mobile && (
        <div id="keystone-menu" className={className}>
            <div id="menu-navigation">
                <button id="menu-collapse" onClick={toggleMenu}>
                    <img id="menu-collapse-icon" src={menuOpen ? CaretLeft32px : CaretRight32px} alt="caret-right32px" />
                </button>
                <div id="menu-company">
                    <img id="company-logo" src={AppIcon} alt="caret-right32px" />
                    <p id="company-name">Materialize</p>
                </div>
            </div>
            <div id="menu-content" className={className}>
                <button className={props?.page === 'inventory' ? 'content-row active' : 'content-row'} onClick={() => setPage('inventory')}>
                    {menuOpen && <p className="row-label">Inventory</p>}
                    <img className="row-icon" src={Home32px} alt="Inventory Page Icon" />
                </button>
                <button className={props?.page === 'offers' ? 'content-row active' : 'content-row'} onClick={() => setPage('offers')}>
                    {menuOpen && <p className="row-label">Offers</p>}
                    <img className="row-icon" src={Speaker32px} alt="Offers Page Icon" />
                </button>
                <button className={props?.page === 'shipments' ? 'content-row active' : 'content-row'} onClick={() => setPage('shipments')}>
                    {menuOpen && <p className="row-label">Shipments</p>}
                    <img className="row-icon" src={Truck32px} alt="Shipping Page Icon" />
                </button>
                <button className={props?.page === 'payouts' ? 'content-row active' : 'content-row'} onClick={() => setPage('payouts')}>
                    {menuOpen && <p className="row-label">Payouts</p>}
                    <img className="row-icon" src={Bill32px} alt="Payouts Page Icon" />
                </button>
                <button className={props?.page === 'team' ? 'content-row active' : 'content-row'} onClick={() => setPage('team')}>
                    {menuOpen && <p className="row-label">Team</p>}
                    <img className="row-icon" src={Team32px} alt="Team Page Icon" />
                </button>
                <button className={props?.page === 'settings' ? 'content-row active' : 'content-row'} onClick={() => setPage('settings')}>
                    {menuOpen && <p className="row-label">Settings</p>}
                    <img className="row-icon" src={Gear32px} alt="Settings Page Icon" />
                </button>
            </div>
            <div id="menu-footer">
                <div id="menu-user-avatar">
                    <p id="user-initials">EB</p>
                </div>
                <div id="menu-user">
                    <p id="user-name">Evan Bashir</p>
                    <p id="user-title">Founder</p>
                </div>
                <div id="menu-user-avatar">
                    <p id="user-initials">EB</p>
                </div>
            </div>
        </div>
    );
};

export default Menu;
import './style/CellPreview.css';
import React, { useState, useRef, useEffect } from 'react';

const CellPreview = ({ rowNumber, columnName, value, message}) => {
    return (
        <div className="keystone-cell-preview product-horizontal-container align-center">
            <div className="product-vertical-container align-center">
                <p className="sf-mono cell-preview-column-name">{columnName}</p>
                <div className="cell-preview-value-wrapper width-100">
                    <p className="sf-mono cell-preview-value center-text" style={{opacity: value ? '1' : '0'}}>{value || 0}</p>
                </div>
                {message && <p className="sf-mono cell-preview-message">{message.includes("MISSING_REQUIRED_FIELD") ? "Missing Value" :
                message.includes("INVALID_QUANTITY") || message.includes("INVALID_MINIMUM_PRICE") ? "Negative Value" : message}</p>}
            </div>
        </div>
    );
};

export default CellPreview;
import './style/LoaderBlock.css';
import React, { useState, useRef, useEffect } from 'react';

const LoaderBlock = ({ loading }) => {
    return (
        <div className="keystone-loader-block">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    );
};

export default LoaderBlock;
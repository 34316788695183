import './style/Content.css';
import React, { useState, useRef, useEffect } from 'react';
import Loader from './Loader';
import Placeholder from '../../media/150.png';
import InventoryList from './InventoryList';
import ProductDetails from './ProductDetails';
import SearchIssueDisplay from '../../media/search-issue-display@3x.png';
import Icon from './Icon';
import Settings from './Settings';
import Offers from './Offers';

const Content = ({ props, setProps, productData, setProductData, userPreferences, setUserPreferences, inventoryData, setShowModal, handleFileUpload, file, fileName, removeFile, fileInputRef, demoCompleted, selectedInventoryId, setSelectedInventoryId, modalType, setModalType}) => {
    const searchInputRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        if (selectedInventoryId && inventoryData) {
            let item = inventoryData.filter(item => item.id === selectedInventoryId)?.[0];
            setProductData(item);
        }
    }, [inventoryData, selectedInventoryId, setProductData]);

    const searchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const searchPart = () => {
        setSearching(true);
    };

    return (
        <div id="keystone-content">
            <div id="content-header">
                <button id="toggle-tray" style={!selectedInventoryId || props?.page === "settings" ? { opacity: '0' } : { opacity: '1' }} onClick={() => setSelectedInventoryId(null)}>Go Back</button>
                <h3>{props?.page?.charAt(0).toUpperCase() + props?.page?.slice(1)}</h3>
                <button id="toggle-tray" style={!selectedInventoryId ? { opacity: '0' } : { opacity: '0' }} onClick={() => setProps({ ...props, trayOpen: !props?.trayOpen})}>Go Back</button>
            </div>
            {!productData && !demoCompleted ? (
                <div id="content-body" className="upload-section">
                    <img id="inventory-placeholder" src={Placeholder} alt="Inventory Placeholder" />
                    <h1>Let's get started!</h1>
                    <div id="try-wrapper">
                        <h2 id="try-label">Kick the tires</h2>
                        <input id="try-input" onChange={searchInputChange} ref={searchInputRef} type="text" placeholder="Enter a part number" />
                        <button id="try-button" onClick={searchPart} disabled={searchValue === ''}>Try</button>
                    </div>
                    <h4>
                        Or drag and drop or&nbsp;
                        <label htmlFor="file-upload" className="file-label">
                            select to upload 
                            <input 
                                type="file"
                                id="file-upload" 
                                className="file-input" 
                                accept=".csv, .xlsx" 
                                onChange={handleFileUpload}
                                ref={fileInputRef} />
                        </label>
                        &nbsp;a .csv or .xlsx file of your excess inventory.
                    </h4>
                    {file &&
                        <div id="uploaded-file-wrapper">
                            <p>{fileName}</p>
                            <button id="remove-file" onClick={removeFile}>Remove</button>
                        </div>
                    }
                </div>
            ) : productData && (selectedInventoryId || !demoCompleted) && props?.page === "inventory" ? (
                <ProductDetails
                    props={props}
                    setProps={setProps}
                    demoCompleted={demoCompleted}
                    productData={productData}
                    setShowModal={setShowModal}
                    modalType={modalType}
                    setModalType={setModalType}
                />
            ) : demoCompleted && props?.page === "inventory" ? (
                <InventoryList
                    inventoryData={inventoryData} 
                    userPreferences={userPreferences}
                    setUserPreferences={setUserPreferences}
                    setSelectedInventoryId={setSelectedInventoryId}
                />
            ) : props?.page === "settings" ? (
                <Settings 
                    props={props}
                    setProps={setProps}
                    />
            ): props?.page === "offers" ? (
                <Offers
                    props={props}
                    setProps={setProps}
                    setShowModal={setShowModal}
                    setModalType={setModalType}
                    />
            ): (
                <div className="product-vertical-container pad-24 align-center flex-1 justify-center">
                    <Icon icon={SearchIssueDisplay} size={120} />
                    <p>Something went wrong</p>
                </div>
            )}
            {!demoCompleted && <div id="content-footer">
                <div className="info-block">
                    <h4>You're in control</h4>
                    <p>Blahdidljdlaksjdlkjdlkajsldjdl</p>
                </div>
                <div className="info-block outer-divider">
                    <h4>We do not share or sell data</h4>
                    <p>Blahdidljdlaksjdlkjdlkajsldjdl</p>
                </div>
                <div className="info-block">
                    <h4>Automatic item flagging</h4>
                    <p>Blahdidljdlaksjdlkjdlkajsldjdl</p>
                </div>
            </div>}
            {searching && <Loader loading={searching} />}
        </div>
    );
};

export default Content;
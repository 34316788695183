import './style/InventoryAccessFlow.css';
import React, { useState, useRef, useEffect } from 'react';

const InventoryAccessFlow = ({ setShowModal }) => {
    return (
        <div className="keystone-upload-flow modal-wrapper">
            <div className="product-vertical-container pad-24 v-gap-16">
                <p className="sf-pro">reasons why we hide by default plus link to setting</p>
                <p className="sf-pro">no commitment to accepting offers</p>
                <p className="sf-pro">we automatically negotiate offers leveraging market insights</p>
                <p className="sf-pro">you handle shipping and handling, we pay for it</p>
                <div className="product-horizontal-container justify-end h-gap-16">
                    <button className="ks-button brand">Go to Setting</button>
                </div>
            </div>
        </div>
    );
};

export default InventoryAccessFlow;
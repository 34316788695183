import './style/SettingSection.css';
import React, { useState, useRef, useEffect } from 'react';
import Setting from './Setting';
const SettingSection = ({ props, setProps, name, settings }) => {
    return (
        <div className="keystone-setting-section product-vertical-container v-gap-8">
            <p className="section-label">{name}</p>
            { settings.map((setting, index) => {
                return <Setting
                    key={index}
                    props={props}
                    setProps={setProps}
                    name={setting.name}
                    enabledTitle={setting.enabledTitle}
                    disabledTitle={setting.disabledTitle}
                    enabledDescription={setting.enabledDescription}
                    disabledDescription={setting.disabledDescription} />
            })}
        </div>
    );
};

export default SettingSection;